<template>
    <section class="main-content">
        <div class="cms-content">
            <div class="container">
                <div class="row justify-content-center">
                    <!-- smartwizard -->
                    <bank-account-wizard :wizardTimeLines="wizardTimeLines"/>
                    <div class="col-xl-8 col-lg-10 col-md-12">
                        <ul class="wizard-mobile list-unstyled">
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,i) in mobileViewTimeLine.activeTimeLines" :key="'active_'+i">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link" :class="{'filled':timeline.filledClass,'active':timeline.activeClass}">
                                    <span>{{ timeline.name }}</span>
                                    <i :class="timeline.classList"></i>
                                </router-link>
                            </li>
                            <li class="main-form-area">
                                <ValidationObserver v-slot="{ passes }">
                                    <form class="open-account" @submit.prevent="passes(accountSpecification)" id="open_account" autocomplete="off">
                                        <div class="card card-top-line">
                                            <div class="card-body">
                                                <h2 class="page-title">Account Specification</h2>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                                                            <div class="country-dropdown">
                                                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':accountDetails.country && !errors[0], 'mb-0':apiErrorCode == 'V0052-005', 'no-disable-style':applicantType==''}">
                                                                    <span class="float-label">
                                                                    <label for="Country">Open Bank Account in*</label>
                                                                        <multiselect
                                                                            placeholder=""
                                                                            v-model="accountDetails.country" 
                                                                            :show-labels="false" 
                                                                            :options="countrys_options" 
                                                                            :searchable="false" 
                                                                            label="name" 
                                                                            track-by="name"
                                                                            :disabled="true"
                                                                            @input="onSelectCountry">
                                                                            <template slot="option" slot-scope="props">
                                                                                <span :class="props.option.flagClass"></span>
                                                                                <div class="option__desc">
                                                                                    <span class="option__title">{{ props.option.name }}</span>
                                                                                </div>
                                                                            </template>
                                                                        </multiselect> 
                                                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                        <i class="fa fa-check" v-if="accountDetails.country && !errors[0]"></i>
                                                                    </span>
                                                                    <!-- <span v-if="errors.length && errors[0]">Please provide open bank account in</span> -->
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0052-005'">{{apiErrorMessage}}</span> 
                                                    </div>
                                                    <div class="col-md-6">
                                                        <ValidationProvider name="bank-account" rules="required" v-slot="{ errors }">
                                                            <div class="country-dropdown">
                                                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':accountDetails.bankName && !errors[0], 'mb-0':apiErrorCode == 'V0052-006', 'no-disable-style':applicantType==''}">
                                                                    <span class="float-label">
                                                                    <label for="Country">Open Bank Account With*</label>
                                                                        <multiselect
                                                                            placeholder=""
                                                                            v-model="accountDetails.bankName" 
                                                                            :show-labels="false" 
                                                                            :options="bankname_options" 
                                                                            :searchable="false" 
                                                                            label="name" 
                                                                            :disabled="true"
                                                                            track-by="name">
                                                                            <template slot="option" slot-scope="props">
                                                                                <img :src="props.option.flagClass">
                                                                                <div class="option__desc">
                                                                                    <span class="option__title">{{ props.option.name }}</span>
                                                                                </div>
                                                                            </template>
                                                                        </multiselect> 
                                                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                        <i class="fa fa-check" v-if="accountDetails.bankName && !errors[0]"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0052-006'">{{apiErrorMessage}}</span>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-end flex-column flex-lg-row mt-4" v-if="!accountDetails.bankName">
                                                    <div class="d-flex align-items-center justify-content-between flex-column flex-sm-row">
                                                        <router-link to="/open-bank-account" class="btn btn-outline-danger btn-mw mr-sm-1 mr-0 my-sm-0 my-2 btn-lg">Back</router-link>
                                                        <b-button class="btn-mw" type="submit" variant="primary" size="lg">Next</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card card-top-line" v-if="accountDetails.bankName">
                                            <div class="card-body">
                                                <h2 class="page-title">Account Type</h2>
                                                <div class="form-group radio-box mb-5">
                                                    <div class="d-flex align-items-center justify-content-around flex-column flex-lg-row">
                                                        <label class="fancy-radio mb-0">
                                                            <input name="accountType" value="personal" type="radio" v-model="accountDetails.accountName" :checked="setAccountType == 'personal'"  :disabled="setAccountType != 'personal' || applicantType!=''">
                                                            <span>Personal/individual Account</span>
                                                        </label>
                                                        <label class="fancy-radio mb-0">
                                                            <input name="accountType" value="joint" type="radio" v-model="accountDetails.accountName" :checked="setAccountType == 'joint'" :disabled="setAccountType != 'joint' || applicantType!=''">
                                                            <span>Joint Account</span>
                                                        </label>
                                                        <label class="fancy-radio mb-0">
                                                            <input name="accountType" value="business" type="radio" v-model="accountDetails.accountName" disabled>
                                                            <span>Business account</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="form-repeat" :class="{'type-remove':accountDetails.accountType.length > 0}">
                                                    <ul class="list-unstyled d-flex justify-content-between flex-column flex-lg-row" v-for="(type,index) in accountDetails.accountType" :key="index">
                                                        <li>
                                                            <div class="country-dropdown">
                                                                <div class="form-group form-select multiselect-grp">
                                                                    <span class="float-label">
                                                                    <label :for="'accountType'+index">Account type</label>
                                                                        <multiselect
                                                                            placeholder=""
                                                                            v-model="accountDetails.accountType[index]" 
                                                                            :show-labels="false" 
                                                                            :options="accountTypes.accountType_options" 
                                                                            :searchable="false"
                                                                            :disabled="applicantType!=''"
                                                                            @input="displayAccountService(); selectAccountType(index)">
                                                                        </multiselect> 
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="country-dropdown">
                                                                <div class="form-group form-select multiselect-grp">
                                                                    <span class="float-label">
                                                                    <label :for="'currency'+index">Account Currency</label>
                                                                        <multiselect
                                                                            placeholder=""
                                                                            v-model="accountDetails.currency[index]" 
                                                                            :show-labels="false" 
                                                                            :options="accountTypes.currency_options" 
                                                                            :searchable="false"
                                                                            label="name" 
                                                                            track-by="name"
                                                                            :disabled="applicantType!=''"
                                                                            @input="displayAccountService()">
                                                                            <template slot="option" slot-scope="props">
                                                                                <div class="option-title">
                                                                                    <span :class="props.option.flagClass" class="mr-2"></span>
                                                                                    <span>{{ props.option.name }}</span>
                                                                                </div>
                                                                            </template>
                                                                        </multiselect> 
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="country-dropdown">
                                                                <div class="form-group form-select multiselect-grp">
                                                                    <span class="float-label">
                                                                    <label :for="'purpose'+index">Purpose of Account</label>
                                                                        <multiselect
                                                                            placeholder=""
                                                                            v-model="accountDetails.purpose[index]" 
                                                                            :show-labels="false" 
                                                                            :options="accountTypes.purpose_options" 
                                                                            :searchable="false"
                                                                            :disabled="applicantType!=''"
                                                                            @input="displayAccountService()">
                                                                        </multiselect> 
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="add-row-btn">
                                                                <b-button class="" variant="outline-danger" size="lg" @click="removeAccountType(index)" v-if="accountDetails.accountType.length > 1"><i class="fa fa-times"></i></b-button>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <span class="text-danger d-block" v-if="hasError == true">{{accountTypeError}}</span>
                                                    <span class="text-danger d-block" v-if="apiErrorCode == 'V0052-000'">{{apiErrorMessage}}</span>
                                                    <div class="text-right">
                                                        <router-link to="#" @click.native="addNewType">Add New Type</router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="card card-top-line" v-if="displayCard">
                                            <div class="card-body">
                                                <h2 class="page-title">Account Services</h2>
                                                <div>
                                                    <div class="col-md-12">
                                                        <ul class="list-unstyled list-email-received">
                                                            <div class="row">
                                                                <li v-for="item in account_services" :key="item.value" class="col-lg-4">
                                                                    <label class="fancy-checkbox">
                                                                    <input type="checkbox" v-model="accountDetails.accountService" :value="item.value" :disabled="applicantType!=''"><span>{{item.text}}</span></label>
                                                                </li>
                                                            </div>
                                                        </ul>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0052-002'">{{apiErrorMessage}}</span>
                                                    </div>
                                                </div>
                                                <div class="form-group radio-box mt-3">
                                                    <label><strong>Statement Preferences</strong></label>
                                                    <div class="d-flex align-items-center justify-content-around flex-column flex-lg-row">
                                                        <label class="fancy-radio mb-0">
                                                            <input name="mood" value="post" type="radio" v-model="accountDetails.preference" checked :disabled="applicantType!=''">
                                                            <span>Post</span>
                                                        </label>
                                                        <label class="fancy-radio mb-0">
                                                            <input name="mood" value="hold" type="radio" v-model="accountDetails.preference" :disabled="applicantType!=''">
                                                            <span>Hold</span>
                                                        </label>
                                                        <label class="fancy-radio mb-0">
                                                            <input name="mood" value="special-delivery" type="radio" v-model="accountDetails.preference" :disabled="applicantType!=''">
                                                            <span>Special Delivery</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            
                                                <div class="form-group radio-box">
                                                    <label><strong>Statement frequency</strong></label>
                                                    <div class="d-flex align-items-center justify-content-around flex-column flex-lg-row">
                                                        <!-- radio -->
                                                        <label class="fancy-radio mb-0">
                                                            <input name="frequency" value="monthly" type="radio" v-model="accountDetails.frequency" checked :disabled="applicantType!=''">
                                                            <span>Monthly</span>
                                                        </label>
                                                        <label class="fancy-radio mb-0">
                                                            <input name="frequency" value="quarterly" type="radio" v-model="accountDetails.frequency" :disabled="applicantType!=''">
                                                            <span>Quarterly</span>
                                                        </label>
                                                        <label class="fancy-radio mb-0">
                                                            <input name="frequency" value="yearly" type="radio" v-model="accountDetails.frequency" :disabled="applicantType!=''">
                                                            <span>Yearly</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-end flex-column flex-lg-row mt-4">
                                                    <div class="d-flex align-items-center justify-content-between flex-column flex-sm-row">
                                                        <router-link to="/open-bank-account" @click.native="removesummary()" class="btn btn-outline-danger btn-mw mr-sm-1 mr-0 my-sm-0 my-2 btn-lg">Back</router-link>
                                                        <b-button class="btn-mw" type="submit" variant="primary" size="lg">Next</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </ValidationObserver>
                            </li>
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,index) in mobileViewTimeLine.inActiveTimeLines" :key="'inactive_'+index">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link">
                                    <span>{{ timeline.name }}</span>
                                    <i :class="timeline.classList"></i>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- logo footer -->
                <bank-account-logo-footer/>
                <!-- Expiry Token Modal -->
                <expiry-token-modal></expiry-token-modal>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </section>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default
const BankAccountLogoFooter = require('@/components/admin/open_bank_account_v1/BankAccountLogoFooter.vue').default
const BankAccountFormWizard = require('@/components/admin/open_bank_account_v2/BankAccountFormWizard.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
const ExpiryTokenModal = require('@/components/admin/open_bank_modal/ExpiryTokenModal.vue').default
export default {
    name:'AccountSpecificationV2Component',
    components:{
        Multiselect,
        ValidationObserver,
        ValidationProvider,
        'bank-account-logo-footer':BankAccountLogoFooter,
        'bank-account-wizard':BankAccountFormWizard,
        'main-loader':LoaderComponent,
        'expiry-token-modal':ExpiryTokenModal
    },
    data(){
        return{
            accountTypeError: '',
            hasError: false,
            applicantType:'',

            timeoutModalShow:false,

            accountDetails:{
                country: { name: 'Ghana', flagClass: "flag-icon flag-icon-gh fi-xl fi-round"}, //null,
                bankName:{ name: 'Consolidated Bank Ghana', flagClass:require(`@/assets/open-account/Consolidated_Bank.png`) }, //null,
                accountName: '',
                currency:[null],
                accountType:[null],
                purpose:[null],
                accountService:[],
                preference: 'post',
                frequency: 'monthly'
            },

            apiErrorCode:'',
            apiErrorMessage:'',
            setAccountType:'',

            countrys_options: [
                { name: 'Ghana', flagClass: "flag-icon flag-icon-gh fi-xl fi-round"},
                // { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                // { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                // { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                // { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

            bankname_options:[],

            account_services: [
                {value: 'Internet Banking', text: 'Internet Banking'},
                {value: 'Debit Card', text: 'Debit Card'},
                {value: 'Cheque Book', text: 'Cheque Book'},
                {value: 'Standing Order', text: 'Standing Order'},
                {value: 'e-Statement', text: 'e-Statement'},
                {value: 'e-Alert', text: 'e-Alert'},
                {value: 'SMS Alert', text: 'SMS Alert'}
            ],
            accountTypes:{   
                // accountType_options:['Saving Account','Current Account'],
                accountType_options:['Efie Ne Fie Hybrid','Forex Account','Foreign Account'],
                currency_options: [],
                // currency_options: [
                //     { name: 'GHS', flagClass: "flag-icon flag-icon-gh fi-xl fi-round"},
                //     { name: 'GBP', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                //     { name: 'EUR', flagClass: "flag-icon flag-icon-eu fi-xl fi-round"},
                //     { name: 'USD', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
                // ],
                purpose_options:['Personal Saving','Loan Servicing','Transactional','Salaries','Investment','Others'],
            },

            wizardTimeLines:[
                {
                    name:'Account Specification',
                    links:'/open-bank-account/account-specification',
                    filledClass:false,
                    activeClass:true
                },
                {
                    name:'Personal Information',
                    links:'/open-bank-account/personal-information',
                    filledClass:false,
                    activeClass:false
                },
                {
                    name:'Next of Kin',
                    links:'/open-bank-account/your-next-of-kin',
                    filledClass:false,
                    activeClass:false
                },
                {
                    name:'Required Documents',
                    links:'/open-bank-account/upload-passport-size-photo',
                    filledClass:false,
                    activeClass:false
                },
                {
                    name:'Confirm and Sign',
                    links:'/open-bank-account/declarations-and-signatures',
                    filledClass:false,
                    activeClass:false
                }
            ],
            displayCard: false,

            mobileViewTimeLine:{
                activeTimeLines:[
                    {
                        name: 'Account Specification',
                        link: '/open-bank-account/account-specification',
                        classList: 'fa fa-chevron-down',
                        filledClass:false,
                        activeClass:true
                    }
                ],

                inActiveTimeLines:[
                    {
                        name: 'Personal Information',
                        link: '/open-bank-account/personal-information',
                        classList: 'fa fa-chevron-right'
                    },
                    {
                        name: 'Next of Kin',
                        link: '/open-bank-account/your-next-of-kin',
                        classList: 'fa fa-chevron-right'
                    },
                    {
                        name: 'Required Documents',
                        link:'/open-bank-account/upload-passport-size-photo',
                        classList: 'fa fa-chevron-right'
                    },
                    {
                        name: 'Confirm and Sign',
                        link: '/open-bank-account/declarations-and-signatures',
                        classList: 'fa fa-chevron-right'
                    }
                ]
            }
        }
    },methods:{
        onSelectCountry(){
            this.bankname_options = [
                { name: 'Consolidated Bank Ghana', flagClass:require(`@/assets/open-account/Consolidated_Bank.png`) },
                // { name: 'Guaranty Trust Bank (Ghana) Limited', flagClass:require(`@/assets/open-account/Guaranty_Trust_Bank.png`)},
                // { name: 'HFC Bank (Ghana) Limited', flagClass:require(`@/assets/open-account/HFC_Bank.jpg`) },
                // { name: 'Universal Merchant Bank (UMB)' , flagClass:require(`@/assets/open-account/Universal_Merchant_Bank.jpg`) },
            ]
        },

        addNewType: function(){
            let that = this;
            that.accountTypes.currency_options = [];
            this.accountDetails.accountType.filter(function (el,index) {
                if (el != null && that.accountDetails.currency[index] != null && that.accountDetails.purpose[index] != null) {
                    that.hasError = false;
                } else {
                    that.hasError = true;
                }
            });

            if(this.hasError == false) {
                this.accountTypeError = '';
                this.accountDetails.accountType.push(null);
                this.accountDetails.currency.push(null);
                this.accountDetails.purpose.push(null);
            } else {
                this.accountTypeError = 'Please select Account Type, Account Currency & Purpose of Account';
            }
        },

        removeAccountType(index){
            this.accountDetails.currency.splice(index, 1)
            this.accountDetails.accountType.splice(index, 1)
            this.accountDetails.purpose.splice(index, 1)
            
            // this.accountTypes.splice(index, 1);
            this.displayAccountService();
        },

        accountSpecification(){
            this.$refs.loader.show();
            if(sessionStorage.getItem('applicantType')){
                this.$refs.loader.hide();
                if(sessionStorage.getItem("pagename") && sessionStorage.getItem("pagename") == 'Summary'){
                    sessionStorage.removeItem("pagename")
                    this.$router.push({path:'/open-bank-account/summary'});
                } else {
                    this.$router.push({path:'/open-bank-account/personal-information'});
                }
            } else {
                setTimeout(() => {
                    let currencyData = [];
                    this.accountDetails.currency.forEach(element => {
                        if(element != null){
                            currencyData.push(element.name);
                        }
                    });

                    let accountType = [];
                    this.accountDetails.accountType.forEach((element,index) => {
                        // console.log(this.accountDetails.accountType[index], currencyData[index], this.accountDetails.purpose[index]);
                        if(this.accountDetails.accountType[index] != null && currencyData[index] != undefined && this.accountDetails.purpose[index] != null) {
                            // console.log('entry'+[index])
                            var accountData = {
                                account_type : this.accountDetails.accountType[index],
                                account_currency : currencyData[index],
                                purpose_of_account : this.accountDetails.purpose[index]
                            }
                            accountType.push(accountData);
                        }
                    });            

                    let bankDetails = {
                        application_id:sessionStorage.getItem("applicationId"),
                        bank_account_in: this.accountDetails.country.name,
                        bank_account_with:this.accountDetails.bankName.name,
                        account_category: this.accountDetails.accountName,
                        account_details: accountType,
                        account_services: this.accountDetails.accountService,
                        statement_preferences: this.accountDetails.preference,
                        statement_frequency: this.accountDetails.frequency
                    }

                    if(sessionStorage.getItem("accountType") == 'joint'){
                        bankDetails.is_joint = true;
                        bankDetails.joint_email = sessionStorage.getItem('secondApplicantEmail');
                    }
                    this.$http.post(this.$baseurl+'bankAccount/account-specification/', bankDetails, {
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                        },
                    })
                    .then((res) => {
                        //Perform Success Action
                        if(res.data.code == '0052-000') {
                            this.$refs.loader.hide();
                            if(sessionStorage.getItem("pagename") && sessionStorage.getItem("pagename") == 'Summary'){
                                sessionStorage.removeItem("pagename")
                                this.$router.push({path:'/open-bank-account/summary'});
                            } else {
                                this.$router.push({path:'/open-bank-account/personal-information'});
                            }
                        } else {
                            // console.log(res);
                            this.$refs.loader.hide();
                            this.$swal('Error!', res.data.message, 'error', 'Close');
                        }
                    })
                    .catch((error) => {
                        // error.response.status Check status code
                        this.$refs.loader.hide();
                        this.apiErrorCode = error.response.data.code;
                        this.apiErrorMessage = error.response.data.message;
                    });  
                }, 2000); 
            }
              
        },

        selectAccountType(selectedIndex){
            let that = this;
            that.accountDetails.currency[selectedIndex] = '';
            that.accountDetails.accountType.filter(function (el,index) {
                if(el == 'Forex Account' || el == 'Foreign Account') {
                    if(selectedIndex == index) {
                        that.accountTypes.currency_options = [
                            { name: 'USD', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
                            { name: 'GBP', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                            { name: 'EUR', flagClass: "flag-icon flag-icon-eu fi-xl fi-round"},
                        ]
                    }
                    
                } else {
                    if(selectedIndex == index) {
                        that.accountTypes.currency_options = [
                            { name: 'GHS', flagClass: "flag-icon flag-icon-gh fi-xl fi-round"},
                        ]
                    }
                }
            })
        },

        displayAccountService(){
            let that = this;
            var accountType = this.accountDetails.accountType.filter(function (el,index) {
                if (el != null && that.accountDetails.currency[index] != null && that.accountDetails.purpose[index] != null) {
                    return true;
                }
                return false;
            });

            this.displayCard = false;
            if( accountType.length > 0){
                this.displayCard = true;
            }
        },

        async getBankData() {
            this.$http.get(this.$baseurl+'bankAccount/bank-user-information/', { 
                params: {application_id: sessionStorage.getItem("applicationId")}, 
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                }
            }).then((res) => {
                //Perform Success Action
                // console.log(res.data);
                if(res.data.code == '0059-000' && res.data.specification_id != ''){
                    this.onSelectCountry();
                    let bankData = res.data;
                    this.accountDetails.country = { name: bankData.bank_account_in };
                    this.accountDetails.bankName = { name: bankData.bank_account_with};
                    this.accountDetails.accountName = bankData.account_category;
                    this.setAccountType = bankData.account_category;
                    this.accountDetails.accountService = bankData.account_services;
                    this.accountDetails.preference = bankData.statement_preferences;
                    this.accountDetails.frequency = bankData.statement_frequency;
                    let accounttypedata = bankData.account_details;
                    this.accountDetails.currency= [];
                    this.accountDetails.accountType= [];
                    this.accountDetails.purpose = [];
                    accounttypedata.forEach((element) => {
                        this.accountDetails.currency.push({ name: element.account_currency});
                        this.accountDetails.accountType.push(element.account_type);
                        this.accountDetails.purpose.push(element.purpose_of_account);
                    })
                    this.displayCard = true;
                } 
            })
            .catch((error) => {
                console.log(error);
                // this.apiErrorCode = error.response.data.code;
            })
        },

        tokenExpired: function() {
            if(sessionStorage.getItem('tokenExpiryTime')){
                if(!this.timeoutModalShow) {
                    const expiryTime = sessionStorage.getItem('tokenExpiryTime').split('.');
                    const currentDateTime = new Date().toISOString().split('.');
                    if(expiryTime[0] == currentDateTime[0]){
                        this.$bvModal.show('expiry_token');
                        this.timeoutModalShow = true;
                    }
                }   
            }            
        },

        removesummary(){
            if(sessionStorage.getItem("pagename")){
                sessionStorage.removeItem("pagename")
            }
        }
    },created() {
        setInterval(this.tokenExpired, 1000);

        if(sessionStorage.getItem("accountType")){
            this.setAccountType = sessionStorage.getItem("accountType");
            this.accountDetails.accountName = sessionStorage.getItem("accountType");
        }

        if(sessionStorage.getItem('applicantType')){
            this.applicantType = sessionStorage.getItem('applicantType');
            this.displayCard = true;
        }
        
        if(sessionStorage.getItem("applicationId")){
            this.getBankData();
        } else {
            this.$swal('Warning!', 'Please start application here', 'warning', 'Close');
            this.$router.push({path:'/'});
        }
    }
}
</script>

