<template>
     <section class="main-content">
        <div class="cms-content">
            <div class="container">
                <div class="row justify-content-center">
                    <!-- smartwizard -->
                    <bank-account-wizard :wizardTimeLines="wizardTimeLines"/>
                    <div class="col-lg-8 col-md-10 col-sm-12">
                        <ul class="wizard-mobile list-unstyled">
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,i) in mobileViewTimeLine.activeTimeLines" :key="'active_'+i">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link" :class="{'filled':timeline.filledClass,'active':timeline.activeClass}">
                                    <span>{{ timeline.name }}</span>
                                    <i :class="timeline.classList"></i>
                                </router-link>
                            </li>
                            <li class="main-form-area">
                                <ValidationObserver v-slot="{ passes }" ref="form">
                                    <form class="personal-information" @submit.prevent="passes(personalInformation)" autocomplete="off">
                                        <div class="card card-top-line">
                                            <div class="card-body">
                                                <h2 class="page-title">Personal Information</h2>
                                                <div class="row">
                                                    <div class="col-md-6 col-12">
                                                        <ValidationProvider name="title" rules="required" v-slot="{ errors}">
                                                            <div class="country-dropdown">
                                                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':accountDetails.title && !errors[0], 'mb-0':apiErrorCode == 'V0053-002'}">
                                                                    <span class="float-label">
                                                                    <label for="Country">Please Select Title*</label>
                                                                        <multiselect
                                                                            ref="title"
                                                                            placeholder=""
                                                                            v-model="accountDetails.title" 
                                                                            :show-labels="false" 
                                                                            :options="title_options" 
                                                                            :searchable="false">
                                                                        </multiselect> 
                                                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                        <i class="fa fa-check" v-if="accountDetails.title && !errors[0]"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0053-002'">{{apiErrorMessage}}</span>
                                                        <ValidationProvider name="first_name" rules="required" v-slot="{ errors}">
                                                            <div class="form-group" :class="{'errors': errors.length && !accountDetails.first_name,'success':accountDetails.first_name, 'mb-0':apiErrorCode == 'V0053-004'}">
                                                                <span class="float-label">
                                                                    <input type="text" ref="first_name" v-model="accountDetails.first_name" class="form-control" id="first_name" placeholder="First Name*" @input="validateName($event,'First Name','first_name')">
                                                                    <label for="first_name" class="control-label">First Name*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.first_name"></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.first_name"></i>
                                                                </span>
                                                                <span class="text-danger error-msg" v-if="fieldName == 'First Name'">{{fieldErrorMsg}}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0053-004'">{{apiErrorMessage}}</span>
                                                        <div class="form-group">
                                                            <span class="float-label">
                                                                <input type="text" v-model="accountDetails.middle_name" class="form-control" id="middle_name" placeholder="Middle Name" @input="validateName($event,'Middle Name','middle_name')">
                                                                <label for="middle_name" class="control-label">Middle Name</label>
                                                                <div class="line"></div>
                                                            </span>
                                                            <span class="text-danger error-msg" v-if="fieldName == 'Middle Name'">{{fieldErrorMsg}}</span>
                                                        </div>
                                                        <ValidationProvider name="last_name" rules="required" v-slot="{ errors}">
                                                            <div class="form-group" :class="{'errors': errors.length && !accountDetails.last_name,'success':accountDetails.last_name, 'mb-0':apiErrorCode == 'V0053-006'}">
                                                                <span class="float-label">
                                                                    <input type="text" ref="last_name" v-model="accountDetails.last_name" class="form-control" id="last_name" placeholder="Last Name*" @input="validateName($event,'Last Name','last_name')">
                                                                    <label for="last_name" class="control-label">Last Name*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.last_name"></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.last_name"></i>
                                                                </span>
                                                                <span class="text-danger error-msg" v-if="fieldName == 'Last Name'">{{fieldErrorMsg}}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0053-006'">{{apiErrorMessage}}</span>
                                                        <div class="form-group">
                                                            <span class="float-label">
                                                                <input type="text" v-model="accountDetails.previous_name" class="form-control" id="previous_name" placeholder="Previous Name (if applicable)" @input="capitalizeFirstLetter($event,'previous_name')">
                                                                <label for="previous_name" class="control-label">Previous Name (if applicable)</label>
                                                                <div class="line"></div>
                                                            </span>
                                                            <!-- <span class="text-danger error-msg" v-if="fieldName == 'Previous Name'">{{fieldErrorMsg}}</span> -->
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-12">
                                                        <ValidationProvider name="nationality" rules="required" v-slot="{ errors}">
                                                            <div class="country-dropdown">
                                                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':accountDetails.nationality && !errors[0], 'mb-0':apiErrorCode == 'V0053-003'}">
                                                                    <span class="float-label">
                                                                    <label for="Country">Nationality*</label>
                                                                        <multiselect
                                                                            ref="nationality"
                                                                            placeholder=""
                                                                            v-model="accountDetails.nationality" 
                                                                            :show-labels="false" 
                                                                            :options="nationality_options"  
                                                                            label="name" 
                                                                            track-by="name">
                                                                            <template slot="option" slot-scope="props">
                                                                                <span :class="props.option.flagClass"></span>
                                                                                <div class="option__desc">
                                                                                    <span class="option__title">{{ props.option.name }}</span>
                                                                                </div>
                                                                            </template>
                                                                        </multiselect> 
                                                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                        <i class="fa fa-check" v-if="accountDetails.nationality && !errors[0]"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0053-003'">{{apiErrorMessage}}</span>
                                                        <div class="form-group">
                                                            <span class="float-label">
                                                                <input type="text" v-model="accountDetails.mother_name" class="form-control" id="mother_name" placeholder="Mother's Maiden Name" @input="capitalizeFirstLetter($event,'mother_name')">
                                                                <label for="mother_name" class="control-label">Mother's Maiden Name</label>
                                                                <div class="line"></div>
                                                            </span>
                                                            <span class="text-danger error-msg" v-if="fieldName == 'mother_name'">{{fieldErrorMsg}}</span>
                                                        </div>
                                                        <ValidationProvider name="maritalStatus" rules="required" v-slot="{ errors}">
                                                            <div class="country-dropdown">
                                                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':accountDetails.maritalStatus && !errors[0], 'mb-0':apiErrorCode == 'V0053-003'}">
                                                                    <span class="float-label">
                                                                    <label for="Country">Marital Status*</label>
                                                                        <multiselect
                                                                            ref="maritalStatus"
                                                                            placeholder=""
                                                                            v-model="accountDetails.maritalStatus" 
                                                                            :show-labels="false" 
                                                                            :options="maritalStatus_options" 
                                                                            :searchable="false" >
                                                                        </multiselect> 
                                                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                        <i class="fa fa-check" v-if="accountDetails.maritalStatus && !errors[0]"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0053-008'">{{apiErrorMessage}}</span>
                                                        <ValidationProvider name="gender" rules="required" v-slot="{ errors}">
                                                            <div class="country-dropdown">
                                                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':accountDetails.gender && !errors[0], 'mb-0':apiErrorCode == 'V0053-009'}">
                                                                    <span class="float-label">
                                                                    <label for="Country">Gender*</label>
                                                                        <multiselect
                                                                            ref="gender"
                                                                            placeholder=""
                                                                            v-model="accountDetails.gender" 
                                                                            :show-labels="false" 
                                                                            :options="gender_options" 
                                                                            :searchable="false" >
                                                                        </multiselect> 
                                                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                        <i class="fa fa-check" v-if="accountDetails.gender && !errors[0]"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0053-009'">{{apiErrorMessage}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card card-top-line">
                                            <div class="card-body">
                                                <h2 class="page-title">Date and Place of Birth</h2>
                                                <div class="row">
                                                    <div class="col-md-6 col-12">
                                                        <ValidationProvider name="dob" rules="required" v-slot="{ errors }">
                                                            <div class="form-group datepicker" :class="{'errors': errors[0],'success':accountDetails.dob && !errors[0], 'mb-0':apiErrorCode == 'V0053-012'}">
                                                                <span class="float-label">
                                                                    <b-input-group>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text">Date of Birth*</span>
                                                                            <span class="input-group-text" @click="$refs.dob.showCalendar()"><i class="icon-calendar"></i></span>
                                                                        </div>
                                                                        <datepicker class="form-control" :open-date="accountDetails.defaultDateSet" :disabled-dates="dateOfBirthDisabled.disabledDates" ref="dob" :bootstrap-styling="false" placeholder="" v-model="accountDetails.dob" :disabled="accountDetails.readonlyMode" :typeable="true"></datepicker>
                                                                    </b-input-group>
                                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.dob && !errors[0]"></i>
                                                                </span>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0053-012'">{{apiErrorMessage}}</span>
                                                    </div>
                                                    <div class="col-md-6 col-12">
                                                        <ValidationProvider name="city" rules="required" v-slot="{ errors}">
                                                            <div class="form-group" :class="{'errors': errors.length && !accountDetails.city,'success':accountDetails.city, 'mb-0':apiErrorCode == 'V0053-011'}">
                                                                <span class="float-label">
                                                                    <input type="text" ref="city" v-model="accountDetails.city" class="form-control" id="city" placeholder="City*" @input="capitalizeFirstLetter($event,'city')">
                                                                    <label for="city" class="control-label">City*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.city"></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.city"></i>
                                                                </span>
                                                                <span class="text-danger error-msg" v-if="fieldName == 'city'">{{fieldErrorMsg}}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0053-011'">{{apiErrorMessage}}</span>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-end flex-column flex-lg-row">
                                                    <div class="d-flex align-items-center justify-content-between flex-column flex-sm-row">
                                                        <router-link to="/open-bank-account/account-specification" class="btn btn-outline-danger btn-mw mr-sm-1 mr-0 my-sm-0 my-2 btn-lg" @click.native="removesummary()">Back</router-link>
                                                        <b-button class="btn-mw" type="submit" variant="primary" size="lg" @click="focusOnFirstError()">Next</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </ValidationObserver>
                            </li>
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,i) in mobileViewTimeLine.inActiveTimeLines" :key="'inActive_'+i">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link"><span>{{ timeline.name }}</span>
                                <i :class="timeline.classList"></i></router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- logo footer -->
                <bank-account-logo-footer/>
                <!-- Expiry Token Modal -->
                <expiry-token-modal></expiry-token-modal>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </section>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default;
const Datepicker = require('vuejs-datepicker').default
const moment = require('moment')
const BankAccountLogoFooter = require('@/components/admin/open_bank_account_v1/BankAccountLogoFooter.vue').default
const BankAccountFormWizard = require('@/components/admin/open_bank_account_v2/BankAccountFormWizard.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
const ExpiryTokenModal = require('@/components/admin/open_bank_modal/ExpiryTokenModal.vue').default
var eighteenYearFromNow = new Date();
eighteenYearFromNow.setFullYear(eighteenYearFromNow.getFullYear() - 18);
var eighteenYearPreviousDate = moment(eighteenYearFromNow).format("YYYY,MM,DD");
export default {
    name:'PersonalInformationV2Component',
    components:{
        Multiselect,
        ValidationObserver,
        ValidationProvider,
        Datepicker,
        'bank-account-logo-footer':BankAccountLogoFooter,
        'bank-account-wizard':BankAccountFormWizard,
        'main-loader':LoaderComponent,
        'expiry-token-modal':ExpiryTokenModal
    },
    data(){
        return{
            accountDetails:{
                title:null,
                first_name:'',
                middle_name:'',
                last_name:'',
                previous_name:'',
                mother_name:'',
                maritalStatus:null,
                gender:null,
                nationality:null,
                city:'',
                dob:'',
                defaultDateSet:new Date(eighteenYearPreviousDate)
            },
            apiErrorCode:'',
            apiErrorMessage:'',

            fieldName:'',
            fieldErrorMsg:'',
            timeoutModalShow:false,

            title_options:['Mr.', 'Mrs.','Miss','Dr.','Ms.','Prof.','Rev.','Lady','Sir','Capt.','Major','Lt.-Col.','Col.','Lady','Lt.-Cmdr.','Hon.','Cmdr.','Flt. Lt.','Brgdr.','Judge','Lord'],
            maritalStatus_options:['Married','Single'],
            gender_options:['Male','Female'],
            nationality_options:[],
            // nationality_options:[
            //     { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
            //     { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            //     { name: 'Germany', flagClass:"flag-icon flag-icon-de fi-xl fi-round"},
            //     { name: 'Italy', flagClass: "flag-icon flag-icon-it fi-xl fi-round"},
            //     { name: 'Australia', flagClass: "flag-icon flag-icon-au fi-xl fi-round"},
            //     { name: 'Canada', flagClass: "flag-icon flag-icon-ca fi-xl fi-round"},
            //     { name: 'Netherlands', flagClass: "flag-icon flag-icon-nl fi-xl fi-round"},
            //     { name: 'Spain', flagClass: "flag-icon flag-icon-es fi-xl fi-round"},
            //     { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"}
            // ],

            wizardTimeLines:[
                {
                    name:'Account Specification',
                    links:'/open-bank-account/account-specification',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Personal Information',
                    links:'/open-bank-account/personal-information',
                    filledClass:false,
                    activeClass:true
                },
                {
                    name:'Next of Kin',
                    links:'/open-bank-account/your-next-of-kin',
                    filledClass:false,
                    activeClass:false
                },
                {
                    name:'Required Documents',
                    links:'/open-bank-account/upload-passport-size-photo',
                    filledClass:false,
                    activeClass:false
                },
                {
                    name:'Confirm and Sign',
                    links:'/open-bank-account/declarations-and-signatures',
                    filledClass:false,
                    activeClass:false
                }
            ],

            dateOfBirthDisabled : {
                disabledDates: {
                    to: new Date(1850,12,29), // Disable all dates up to specific date
                    from: new Date(eighteenYearPreviousDate), // Disable all dates after specific date
                }
            },

            mobileViewTimeLine:{
                activeTimeLines:[
                    {
                        name: 'Account Specification',
                        link: '/open-bank-account/account-specification',
                        classList: 'fa fa-chevron-right',
                        filledClass:true,
                        activeClass:false
                    },
                    {
                        name: 'Personal Information',
                        link: '/open-bank-account/personal-information',
                        classList: 'fa fa-chevron-down',
                        filledClass:false,
                        activeClass:true
                    },
                ],

                inActiveTimeLines:[
                    {
                        name: 'Next of Kin',
                        link: '/open-bank-account/your-next-of-kin',
                        classList: 'fa fa-chevron-right'
                    },
                    {
                        name: 'Required Documents',
                        link:'/open-bank-account/upload-passport-size-photo',
                        classList: 'fa fa-chevron-right'
                    },
                    {
                        name: 'Confirm and Sign',
                        link: '/open-bank-account/declarations-and-signatures',
                        classList: 'fa fa-chevron-right'
                    }
                ]
            }
        }
    },methods:{
        personalInformation(){
            this.$refs.loader.show();
            let personalDetails = {
                application_id:sessionStorage.getItem("applicationId"),
                name_title:this.accountDetails.title,
                first_name:this.accountDetails.first_name,
                middle_name:this.accountDetails.middle_name,
                last_name:this.accountDetails.last_name,
                previous_name:this.accountDetails.previous_name,
                mother_maiden_name:this.accountDetails.mother_name,
                marital_status:this.accountDetails.maritalStatus,
                gender:this.accountDetails.gender,
                nationality:this.accountDetails.nationality.name,
                city:this.accountDetails.city,
                date_of_birth:moment(this.accountDetails.dob).format('YYYY-MM-DD')
            }
            // console.log(personalDetails);
            this.$http.post(this.$baseurl+'bankAccount/personal-information/', personalDetails, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                },
            })
            .then((res) => {
                //Perform Success Action
                // console.log(res.data);
                if(res.data.code == '0053-000') {
                    this.$refs.loader.hide();
                    if(sessionStorage.getItem("pagename") && sessionStorage.getItem("pagename") == 'Summary'){
                        sessionStorage.removeItem("pagename")
                        this.$router.push({path:'/open-bank-account/summary'});
                    } else {
                        this.$router.push({path:'/open-bank-account/employment'});
                    }
                } else {
                    this.$refs.loader.hide();
                    this.$swal('Error!', res.message, 'error', 'Close');
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                // console.log(error);
                this.$refs.loader.hide();
                this.apiErrorCode = error.response.data.code;
                this.apiErrorMessage = error.response.data.message;
                // this.$swal('Error!', error.response.data.message, 'error', 'Close');
            });      
        },

        async focusOnFirstError(){
            const success = await this.$refs.form.validate();
            if (!success) {
                var errorsName = Object.keys(this.$refs.form.errors);
                var firstError = ''
                errorsName.map((item) => {
                    if(this.$refs.form.errors[item].length > 0 && !firstError){
                        firstError = item
                    }
                })
                
                if(firstError == 'title' || firstError == 'nationality' || firstError == 'gender' || firstError == 'maritalStatus'){
                    this.$refs[firstError].$el.focus();
                } else if(firstError == 'dob'){
                    this.$refs[firstError].showCalendar()
                }else{
                    this.$refs[firstError].focus();
                }
            }
        },

        validateName(e,fieldType,fieldName) {
            let char = e.target.value; //String.fromCharCode(e.keyCode); // Get the character
            let charLength = e.target.value.length;
            this.accountDetails[fieldName] = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1); //Capitalize first character
            if(/^[A-Za-z'-]+$/.test(char)) {
                if(charLength >= 2 && charLength <= 35) {
                    this.fieldErrorMsg = '';
                    this.fieldName = '';
                    return true; // Match with regex 
                } else {
                    this.fieldName = fieldType;
                    this.fieldErrorMsg = 'Please provide minimum two characters.';
                }
            } else {
                this.fieldName = fieldType;
                this.fieldErrorMsg = 'Please provide valid '+fieldType;
                e.preventDefault(); // If not match, don't add to input text
            }
        },

        capitalizeFirstLetter(event,fieldName){
            var splitStr = event.target.value.split(' ');
            if(splitStr[0] == ''){
                this.fieldName = fieldName;
                this.fieldErrorMsg = 'Please provide minimum two characters.';
            } else {
                this.fieldErrorMsg = '';
                this.fieldName = '';
                for (var i = 0; i < splitStr.length; i++) {
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                }
                // Directly return the joined string
                this.accountDetails[fieldName] = splitStr.join(' ');
            }
        },

        async getNationalityData() {
            try {
                const response = await this.$http.get("https://stand-app.com/api/v1/countries");
                // JSON responses are automatically parsed.
                response.data.map((item) => {
                    let countryData = {
                        name: item.name, 
                        flagClass:"flag-icon flag-icon-"+item.isoCode.toLowerCase()+" fi-xl fi-round"
                    }
                    this.nationality_options.push(countryData);
                })
            } catch (error) {
                console.log(error);
            }
        },   

        async getPersonalData() {
            this.$http.get(this.$baseurl+'bankAccount/bank-user-information/', { 
                params: {application_id: sessionStorage.getItem("applicationId")}, 
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                }
            }).then((res) => {
                //Perform Success Action
                // console.log(res.data);
                if(res.data.code == '0059-000' && res.data.personal_information.user_info_id != ''){
                    let personalInfoData = res.data.personal_information;
                    this.accountDetails.title = personalInfoData.name_title;
                    this.accountDetails.first_name = personalInfoData.first_name;
                    this.accountDetails.middle_name = personalInfoData.middle_name;
                    this.accountDetails.last_name = personalInfoData.last_name;
                    this.accountDetails.previous_name = personalInfoData.previous_name;
                    this.accountDetails.mother_name = personalInfoData.mother_maiden_name;
                    this.accountDetails.maritalStatus = personalInfoData.marital_status;
                    this.accountDetails.gender = personalInfoData.gender;
                    this.accountDetails.nationality = { name: personalInfoData.nationality };
                    this.accountDetails.city = personalInfoData.city;
                    this.accountDetails.dob = personalInfoData.date_of_birth;
                } else {
                    if(res.data.specification_id == ''){
                        this.$swal('Warning!', 'Please complete account specification first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/account-specification'});
                    } else {
                        // let personalInformation = JSON.parse(sessionStorage.getItem("personalInfo"));
                        this.accountDetails.first_name = res.data.user_profile.first_name,
                        this.accountDetails.last_name = res.data.user_profile.last_name
                        if(sessionStorage.getItem("customerDetail")) {
                            let jsonDetail = JSON.parse(sessionStorage.getItem("customerDetail"));
                            this.accountDetails.middle_name = jsonDetail.middle_name;
                            this.accountDetails.gender = jsonDetail.gender.charAt(0).toUpperCase() + jsonDetail.gender.slice(1); //jsonDetail.gender;
                            this.accountDetails.nationality = { name: jsonDetail.nationality.charAt(0).toUpperCase() + jsonDetail.nationality.slice(1) };
                            this.accountDetails.city = jsonDetail.city;
                            this.accountDetails.dob = jsonDetail.dob;
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                // this.apiErrorCode = error.response.data.code;
            })
        },

        tokenExpired: function() {
            if(sessionStorage.getItem('tokenExpiryTime')){
                if(!this.timeoutModalShow) {
                    const expiryTime = sessionStorage.getItem('tokenExpiryTime').split('.');
                    const currentDateTime = new Date().toISOString().split('.');
                    if(expiryTime[0] == currentDateTime[0]){
                        this.$bvModal.show('expiry_token');
                        this.timeoutModalShow = true;
                    }
                }   
            }               
        },

        removesummary(){
            if(sessionStorage.getItem("pagename")){
                sessionStorage.removeItem("pagename")
            }
        }
    },created() {
        this.getNationalityData();
        setInterval(this.tokenExpired, 1000);

        if(sessionStorage.getItem("applicationId")){
            this.getPersonalData();
        } else {
            this.$swal('Warning!', 'Please start application here', 'warning', 'Close');
            this.$router.push({path:'/'});
        }
    }
}
</script>
