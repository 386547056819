<template>
    <header id="header" class="main-header">
        <div class="top-header" v-if="!(currentUrl == 'open-bank-account-variant1' ||
                                currentUrl == 'open-bank-account-v1'||
                                currentUrl == 'open-bank-account'||
                                currentUrl == 'open-bank-account')">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <ul class="list-unstyled d-flex justify-content-end">
                            <li v-for="(detail,index) in client_details" :key="index">
                                <a href="javascript:void(0);">
                                    <img v-for="(image,index) in detail.images" :key="index" :src="image" width="18" alt=""> 
                                    <span>{{ detail.number }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-menu">
            <div class="container-fluid">
                <b-navbar toggleable="lg" class="bg-light p-0">
                    <b-navbar-brand class="header-name" to="/home" v-if="!(currentUrl == 'open-bank-account-variant1' ||
                                currentUrl == 'open-bank-account-v1'||
                                currentUrl == 'open-bank-account'||
                                currentUrl == 'open-bank-account')">
                        <img :src="navbar_details.brand_logo" width="223" alt="Payangel Business">
                    </b-navbar-brand>
                    <b-navbar-toggle target="navbarColor01"></b-navbar-toggle>
                    <b-collapse id="navbarColor01" is-nav>
                        <b-nav class="navbar-nav ml-auto">
                            <b-nav-item v-for="(menu,index) in navbar_details.navbar_menus" :key="index" @click="showModal()">{{ menu }}</b-nav-item>
                            <!-- <b-nav-item-dropdown variant="link" class="country-dropdown">
                                <template #button-content>
                                   <span :class="navbar_details.country_dropdawn.imageClass"></span>{{ navbar_details.country_dropdawn.text_title }}
                                </template>
                                <b-dropdown-item  href="#" v-for="(city,index) in navbar_details.country_dropdawn.citys" :key="index" @click="activeCountry(index)" :active="index == navbar_details.country_dropdawn.active_index">
                                    <span :class="city.imageClass" alt=""></span>{{ city.name }}
                                </b-dropdown-item>
                            </b-nav-item-dropdown> -->
                            <b-nav-item-dropdown variant="link" class="lang-dropdown">
                                <template #button-content>
                                {{ navbar_details.language_dropdawn.text_title }}
                                </template>
                                <b-dropdown-item  href="#" v-for="(city,index) in navbar_details.language_dropdawn.citys" :key="index" @click="activeLang(index)" :active="index == navbar_details.language_dropdawn.active_index">
                                    {{ city.name }}
                                </b-dropdown-item>
                            </b-nav-item-dropdown>
                            <!--default Login Button -->
                            <b-nav-form v-if="(!(currentLayout == 'auth-v2-layout' && 
                                (currentUrl == 'open-bank-account-variant1' ||
                                currentUrl == 'open-bank-account-v1'||
                                currentUrl == 'open-bank-account'||
                                currentUrl == 'open-bank-account')) &&
                                currentLayout != 'customer-layout')">
                                <a href="#" target="_self" class="nav-link btn btn-outline">Log in to Personal</a>
                                <router-link to="/authentication/page-login" class="nav-link btn btn-login">Log in</router-link>
                            </b-nav-form>
                            <!-- customer Login Button -->
                            <b-nav-form v-if="currentLayout == 'customer-layout' && currentUrl != 'customer-login'">
                                <router-link to="/customer-login"  class="nav-link btn btn-outline">Log in to Personal</router-link>
                                <b-button class="nav-link btn btn-login" @click="openSignInModal">Log in</b-button>
                            </b-nav-form>
                        </b-nav>
                    </b-collapse>
                </b-navbar>
            </div>
        </div>
        <b-modal id="signin" title="Login or Register" hide-footer modal-class="customer-home-modal">
            <div class="modal-body">
                <ValidationObserver v-slot="{ passes }">
                    <form class="contact-detail" @submit.prevent="passes(loginOrRegisterSubmit)">
                        <div class="login-card card-mobile" :class="{active: activeformClass}">
                            <div class="country-dropdown">
                                <div class="form-group form-select single-multiselect three-view">
                                    <span class="float-label">
                                        <label for="Country">Country</label>
                                        <multiselect
                                            placeholder=""
                                            :searchable="false"
                                            v-model="loginRegister.contact_country" 
                                            :show-labels="false" 
                                            :options="country_options"  
                                            label="name" 
                                            track-by="name"
                                            @select="setPrimaryNumberCountryCode">
                                            <template slot="option" slot-scope="props">
                                                <div class="option-title">
                                                    <span class="mr-2" :class="props.option.flagClass"></span>
                                                    <span>{{ props.option.name }}</span>
                                                </div>
                                            </template>
                                        </multiselect> 
                                    </span>
                                </div>
                            </div>
                            <div class="form-group" :class="{'errors': mobileNumberError == false,'success':loginRegister.primaryNumber && mobileNumberError}">
                                <div class="float-label">
                                    <label for="primary_number" class="control-label">Mobile Number*</label>
                                    <vue-tel-input
                                        v-model="loginRegister.primaryNumber"
                                        v-bind="mobileNumber"
                                        @validate ="mobileInputValidate"
                                        ref="telInput"
                                    >
                                    <template v-slot:arrow-icon>
                                        <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                    </template>
                                    </vue-tel-input>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="mobileNumberError == false"></i>
                                    <i class="fa fa-check" v-if="mobileNumberError && loginRegister.primaryNumber"></i>
                                </div>
                            </div>
                            <div class="bottom">
                                <b-button variant="gradiunt" size="lg" block @click="loginOrRegisterSubmit()">NEXT</b-button> 
                                <b-button variant="outline-primary" size="lg" block   @click="toggleEmaiMobileInput()">Login by {{ toggleEmailMobileTitle }}</b-button> 
                            </div>  
                        </div> 
                        <div class="login-card card-email" :class="{active: !activeformClass}">
                            <div class="country-dropdown">
                                <div class="form-group form-select single-multiselect">
                                    <span class="float-label">
                                        <label for="Country">Country</label>
                                        <multiselect
                                            placeholder=""
                                            :searchable="false"
                                            v-model="loginRegister.contact_country" 
                                            :show-labels="false" 
                                            :options="country_options"  
                                            label="name" 
                                            track-by="name"
                                            @select="setPrimaryNumberCountryCode">
                                            <template slot="option" slot-scope="props">
                                                <div class="option-title">
                                                    <span class="mr-2" :class="props.option.flagClass"></span>
                                                    <span>{{ props.option.name }}</span>
                                                </div>
                                            </template>
                                        </multiselect> 
                                    </span>
                                </div>
                            </div>
                            <ValidationProvider name="contact_email" :rules="!activeformClass ? 'required|email' : ''" v-slot="{ errors}">
                                <div class="form-group" :class="{'errors': errors.length && loginRegister.contact_email || errors[0],'success':loginRegister.contact_email && !errors[0]}">
                                    <span class="float-label">
                                        <input type="email" v-model="loginRegister.contact_email" class="form-control" name="email1" id="contact_email" placeholder="Email*">
                                        <label for="contact_email" class="control-label">Email*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors.length && !loginRegister.contact_email || errors[0] "></i>
                                        <i class="fa fa-check" v-if="loginRegister.contact_email && !errors[0]"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                            <div class="bottom">
                                <b-button variant="gradiunt"  type="submit" size="lg" block>NEXT</b-button> 
                                <b-button variant="outline-primary" size="lg" block @click="toggleEmaiMobileInput()">Login by {{ toggleEmailMobileTitle }}</b-button> 
                            </div>  
                        </div> 
                    </form>
                </ValidationObserver>
            </div>
        </b-modal>
        <contact-us></contact-us>
    </header>
</template>
<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
import Multiselect from 'vue-multiselect'
const ContactUsModal = require('@/components/admin/commonmodal/ContactusModal.vue').default

export default {
    name: 'AuthHeaderV2Component',
    props:{
        currentLayout:String,
        currentUrl: String
    },
    components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        'contact-us':ContactUsModal
    },data() {
        return {
            activeformClass: true,
            client_details:[
                {
                    images:[
                        require(`@/assets/home_image/outgoing-call.svg`),
                    ],
                    number: '+44 2037-476-765'
                },
                {
                    images:[
                        require(`@/assets/home_image/outgoing-call.svg`),
                        require(`@/assets/home_image/whatsapp-call.svg`),
                    ],
                    number: '+44 790-806-1086'
                }
            ],

            navbar_details:{
                brand_logo: require(`@/assets/home_image/Payangel_Business_main.svg`),
                navbar_menus:[
                    'Contact Us'
                ],
                country_dropdawn:{
                    text_title: 'United Kingdom',
                    imageClass: "flag-icon flag-icon-gb fs-20 mr-2",
                    active_index: 1,
                    citys:[
                        {
                            imageClass: "flag-icon flag-icon-ca fs-20 mr-2",
                            name:'Canada'
                        },
                        {
                            imageClass: "flag-icon flag-icon-gb fs-20 mr-2",
                            name:'United Kingdom'
                        }
                    ]
                },

                language_dropdawn:{
                    text_title: 'EN',
                    active_index: 0,
                    citys:[
                        {
                            name:'EN'
                        },
                        {
                            name:'FR'
                        },
                        {
                            name:'ES'
                        }
                    ]
                },
            },

            loginRegister:{
                contact_email:'',
                contact_country:[{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",primaryNumberCode:'GB'}],
                primaryNumber:"",
            },

            mobileNumberError:'',
            mobileNumber: {
                mode: "international",
                defaultCountry: "GB",
                enabledCountryCode: true,
                enabledFlags: true,
                name: "mobileNumber",
                validCharactersOnly:true,
                inputOptions:{ showDialCode: true, tabindex: 0,id:"primary_number" },
                dropdownOptions:{
                    showFlags: true,
                    disabled:true
                },   
            },

            country_options:[
                { name: 'Canada', flagClass: "flag-icon flag-icon-ca fi-xl fi-round",primaryNumberCode:'CA'},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",primaryNumberCode:'GB'},
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round",primaryNumberCode:'IN'},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round",primaryNumberCode:'FR'},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round",primaryNumberCode:'US'},
            ],
            toggleEmailMobileTitle: 'email',
        }
    },methods:{
        activeCountry(index){
            this.navbar_details.country_dropdawn.active_index = index;
            this.navbar_details.country_dropdawn.text_title = this.navbar_details.country_dropdawn.citys[index].name;
            this.navbar_details.country_dropdawn.imageClass = this.navbar_details.country_dropdawn.citys[index].imageClass;
        },

        activeLang(index){
            this.navbar_details.language_dropdawn.active_index = index;
            this.navbar_details.language_dropdawn.text_title = this.navbar_details.language_dropdawn.citys[index].name;
        },

        loginOrRegisterSubmit(){
            if(typeof(this.mobileNumberError) == 'undefined'){
                this.mobileNumberError = false
                this.loginRegister.contact_email = ''
            }

            if(this.mobileNumberError == true || this.loginRegister.contact_email){
               this.$router.push({ path: '/account'})
            }
        },

        mobileInputValidate(phoneObject){
            this.mobileNumberError = phoneObject.valid
        },


        setPrimaryNumberCountryCode(defaultCountry){
            this.$refs.telInput.choose(defaultCountry.primaryNumberCode)
            this.mobileNumber.defaultCountry = defaultCountry.primaryNumberCode
        },
        
        openSignInModal(){
            this.$bvModal.show('signin');
            setTimeout(() => {
                this.$refs.telInput.choose(this.mobileNumber.defaultCountry)
            }, 300);
        },

        toggleEmaiMobileInput(){
            this.activeformClass = !this.activeformClass
            if(this.activeformClass){
                this.toggleEmailMobileTitle = 'email'
            }else{
                this.toggleEmailMobileTitle = 'mobile number'
            } 
        },

        showModal(){
            this.$bvModal.show('contact_us');
        }
    }
}
</script>
