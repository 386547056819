<template>
    <div class="right-content mx-auto my-auto">
        <ValidationObserver v-slot="{ passes }">
            <div class="header pb-3 text-center">
                <p class="lead px-3">Hello, my name is PayAngel. What's yours</p>
            </div>
            <div class="body">
                <p class="text-center mb-4">Enter your name as it appears on your official ID</p>
                <form class="form-auth-small" @submit.prevent="passes(registerSubmit)" id="registration" ref="registration">
                    <!-- Country -->
                    <ValidationProvider name="select_country" rules="required" v-slot="{ errors}">
                        <div class="country-dropdown">
                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':registerformdata.country && registerformdata.country.length != 0  && !errors[0]}">
                                <span class="float-label">
                                <label for="Country">Select Country*</label>
                                    <multiselect
                                        placeholder=""
                                        v-model="registerformdata.country" 
                                        :show-labels="false" 
                                        :options="countrys_options" 
                                        :searchable="false" 
                                        label="name" 
                                        track-by="name"
                                        @select="setPrimaryNumberCountryCode">
                                        <template slot="option" slot-scope="props">
                                            <span :class="props.option.flagClass"></span>
                                            <div class="option__desc">
                                                <span class="option__title">{{ props.option.name }}</span>
                                            </div>
                                        </template>
                                    </multiselect> 
                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                    <i class="fa fa-check" v-if="registerformdata.country && registerformdata.country.length != 0 && !errors[0]"></i>
                                </span>
                            </div>
                        </div>
                    </ValidationProvider>
                    
                    <!-- Business Name -->
                    <ValidationProvider name="Business Name" rules="required" v-slot="{ errors}">
                        <div class="form-group" :class="{'errors': errors.length && !registerformdata.businessname,'success':registerformdata.businessname}">
                            <span class="float-label">
                                <input type="text" id="business_name" v-model="registerformdata.businessname" class="form-control" placeholder="Business Name*">
                                <label for="business_name" class="control-label">Business Name*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !registerformdata.businessname"></i>
                                <i class="fa fa-check" v-if="registerformdata.businessname"></i>
                            </span>
                            <span class="text-danger error-msg" v-if="errors[0]">Please provide unique business name</span>
                        </div>
                    </ValidationProvider>
                    
                    <!-- Country Code and Mobile Number -->
                    <div class="form-group" :class="{'errors': mobileInputError == false,'success':registerformdata.mobile_number && mobileInputError}">
                        <div class="float-label">
                            <label for="primary_number" class="control-label">Contact Mobile Number*</label>
                            <vue-tel-input
                                v-model="registerformdata.mobile_number"
                                v-bind="primaryMobileNumber"
                                @validate ="mobileInputValidate"
                                ref="telInput">
                            <template v-slot:arrow-icon>
                                <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                            </template>
                            </vue-tel-input>
                            <div class="line"></div>
                            <i class="fa fa-times" v-if="mobileInputError == false"></i>
                            <i class="fa fa-check" v-if="mobileInputError && registerformdata.mobile_number"></i>
                        </div>
                    </div>
                    
                    <!-- Email -->
                    <ValidationProvider name="contact_email" rules="required|email" v-slot="{ errors}">
                        <div class="form-group" :class="{'errors': errors.length && !registerformdata.contact_email || errors[0],'success':registerformdata.contact_email && !errors[0]}">
                            <span class="float-label">
                                <input type="email" v-model="registerformdata.contact_email" class="form-control" name="email1" id="contact_email" placeholder="Contact Email Address*" @blur="sweetAlertForEmail">
                                <label for="contact_email" class="control-label">Contact Email Address*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !registerformdata.contact_email || errors[0] "></i>
                                <i class="fa fa-check" v-if="registerformdata.contact_email && !errors[0]"></i>
                            </span>
                            <span v-if="registerformdata.displayConfirmPopup = registerformdata.contact_email && !errors[0]"/>
                        </div>
                    </ValidationProvider>
                    
                    <!-- Email Yes/No -->
                    <div  v-if="registerformdata.displaylinkedEmail">
                        <ValidationProvider name="profile_password" :rules="registerformdata.displaylinkedEmail ? 'required' : ''" v-slot="{ errors}" v-if="registerformdata.displaylinkedEmail">
                            <div class="head">
                                <label><strong>Enter the password for the existing profile</strong></label>
                            </div>
                            <div class="form-group set-password" :class="{'errors': errors.length && !registerformdata.profile_password,'success':registerformdata.profile_password}">
                                <span class="float-label">
                                    <input :type="registerformdata.password_type" v-model="registerformdata.profile_password" class="form-control" name="profile_password" id="profile_password" placeholder="Password*">
                                    <label for="profile_password" class="control-label">Password*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !registerformdata.profile_password"></i>
                                    <i class="fa fa-check" v-if="registerformdata.profile_password"></i>
                                    <i class="fa fa-eye" :class="registerformdata.password_type == 'password' ? 'grey' : 'success'" aria-hidden="true" @click="showPassword()"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                    </div>
                    
                    <!-- Referral Code -->
                    <div class="d-flex justify-content-between align-items-center m-b-20">
                        <p class="mb-0">Do you have a referral code?</p>
                        <div class="custom-switch success-error-bg">
                            <input type="checkbox" class="custom-switch-input" id="customSwitch1" v-model="registerformdata.is_referral">
                            <label class="custom-switch-label" for="customSwitch1">
                                <span class="custom-switch-text1">Yes</span>
                                <span class="custom-switch-text2">No</span>
                            </label>
                        </div>
                    </div>

                    <ValidationProvider name="referral_value" rules="required" v-slot="{ errors}" v-if="registerformdata.is_referral">
                        <div class="form-group" :class="{'errors': errors.length && !registerformdata.is_referral_value,'success':registerformdata.is_referral_value}">
                            <span class="float-label">
                                <input type="text" v-model="registerformdata.is_referral_value" class="form-control" id="referral_code" placeholder="Enter Referral Code">
                                <label for="referral_code" class="control-label">Code</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !registerformdata.is_referral_value"></i>
                                <i class="fa fa-check" v-if="registerformdata.is_referral_value"></i>
                            </span>
                        </div>
                    </ValidationProvider>

                    <!-- term/condition checkbox -->
                    <div class="form-group clearfix">
                        <label class="fancy-checkbox new-fancy">
                            <input type="checkbox" @click="checkIsChecked($event)">
                            <span>Check here to indicate that you have read and agree to the <strong><a class="font-color" href="/terms-and-conditions" target="_blank">Terms and Conditions</a></strong></span>
                        </label>
                        <span class="text-danger">{{  registerformdata.errorMsg }}</span>
                    </div>
                    <b-button type="submit" block variant="primary" size="lg">CONTINUE</b-button>
                    <div class="bottom">
                        <span>Have an account? <router-link to="/authentication/page-login">Login</router-link></span>
                    </div>
                </form>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
import Multiselect from 'vue-multiselect'
export default {
    name:'RegisterComponent',
    components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect
    },data(){
        return{
            registerformdata: {
                is_referral_value:'',
                is_referral:false,
                linkedEmail:false,
                displayConfirmPopup: false,
                displaylinkedEmail:false,
                profile_password:'',
                country: '',
                contact_email:'',
                mobile_number:'',
                businessname: '',
                robot: false,
                password_type: 'password',
                IsChecked: false,
                errorMsg:'',
                countryflag:''
            },

            countrys_options:[
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",primaryNumberCode:'GB'},
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round",primaryNumberCode:'IN'},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round",primaryNumberCode:'FR'},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round",primaryNumberCode:'US'},
            ],
            
            mobileInputError:'',
            primaryMobileNumber:{
                mode: "international",
                defaultCountry:"GB",
                enabledCountryCode: true,
                enabledFlags: true,
                inputOptions:{ showDialCode: true},
                dropdownOptions:{
                    showFlags: true,
                    disabled:true
                }, 
            },
        }
    },methods: {
        registerSubmit() {
            if(typeof(this.mobileInputError) == 'undefined'){
                this.mobileInputError = false
            }
            if(this.registerformdata.profile_password && this.mobileInputError){
                if(this.registerformdata.IsChecked){
                    this.$router.push({ path: '/authentication/page-passcode' })
                }else{
                    return this.registerformdata.errorMsg = 'Please select checkbox to continue.'
                }
            }else{
                if(this.mobileInputError){
                    if(this.registerformdata.IsChecked){
                        this.$router.push({ path: '/authentication/page-personaldetail' })
                    }else{
                        return this.registerformdata.errorMsg = 'Please select checkbox to continue.'
                    }
                }
            }
        },

        //visible password text
        showPassword() {
            this.registerformdata.password_type = this.registerformdata.password_type === 'password' ? 'text' : 'password';
        },

        sweetAlertForEmail(){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-secondary btn-lg'
                },
                buttonsStyling: false
            })
            if(this.registerformdata.displayConfirmPopup){
                swalWithBootstrapButtons.fire({
                    text: "Do you want to continue the registration for this business with the same email address ?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.registerformdata.displaylinkedEmail = true
                        }else{
                            this.registerformdata.displaylinkedEmail = false
                        } 
                })
            }
        },

        //check Arrow Certification checkbox cheked
        checkIsChecked(event){
            this.registerformdata.IsChecked = event.target.checked
            if(this.registerformdata.IsChecked){
                return this.registerformdata.errorMsg = ''
            }
        },

        setPrimaryNumberCountryCode(defaultCountry) {
            this.$refs.telInput.choose(defaultCountry.primaryNumberCode);
        },
       
        mobileInputValidate(phoneObject){
            this.mobileInputError = phoneObject.valid
        },
    }
}
</script>
