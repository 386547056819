<template>
    <section class="main-content">
        <div class="cms-content">
            <div class="container">
                <div class="row justify-content-center">
                    <!-- smartwizard -->
                    <bank-account-wizard :wizardTimeLines="wizardTimeLines"/>

                     <div class="col-lg-8 col-md-10 col-sm-12">
                        <ul class="wizard-mobile list-unstyled">
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,i) in mobileViewTimeLine.activeTimeLines" :key="'active_'+i">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link" :class="{'filled':timeline.filledClass,'active':timeline.activeClass}">
                                    <span>{{ timeline.name }}</span>
                                    <i :class="timeline.classList"></i>
                                </router-link>
                            </li>
                            <li class="main-form-area">
                                <ValidationObserver v-slot="{ passes }">
                                    <form class="next-kin open-account" @submit.prevent="passes(uploadDocumentsSubmit)">
                                        <div class="card card-top-line">
                                            <div class="card-body">
                                                <div class="page-card-group your-id-page">
                                                    <h2 class="page-title">Your ID</h2>
                                                    <!-- country section -->
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                                                                <div class="country-dropdown">
                                                                    <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':accountDetails.country && !errors[0]}">
                                                                        <span class="float-label">
                                                                        <label for="Country">Select ID Issuing Country*</label>
                                                                            <multiselect
                                                                                placeholder=""
                                                                                v-model="accountDetails.country"
                                                                                :show-labels="false" 
                                                                                :options="countrys_options" 
                                                                                label="name" 
                                                                                track-by="name"
                                                                                @input="selectedCountryGet">
                                                                                <!--  :searchable="false"  -->
                                                                                <template slot="option" slot-scope="props">
                                                                                    <span :class="props.option.flagClass"></span>
                                                                                    <div class="option__desc">
                                                                                        <span class="option__title">{{ props.option.name }}</span>
                                                                                    </div>
                                                                                </template>
                                                                            </multiselect> 
                                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                            <i class="fa fa-check" v-if="accountDetails.country && !errors[0]"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <!-- radio buttons section -->
                                                            <div class="form-group radio-box" v-if="accountDetails.displayDocumentRadioSection">
                                                                <div class="radio-list d-flex flex-column">
                                                                    <label class="fancy-radio mb-3" v-for="(document,i) in documents" :key="i">
                                                                        <input name="documents" v-model="accountDetails.documentName" type="radio" @click="displayUploadDocument($event)" :value="document.name">
                                                                        <span>{{ document.name }}</span>
                                                                    </label>
                                                                </div>
                                                                <span class="text-danger">{{ accountDetails.errorMsg }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-end flex-column flex-lg-row mt-3" v-if="!accountDetails.displayUploadDocumentSection">
                                                        <div class="d-flex align-items-center justify-content-between flex-column flex-sm-row">
                                                            <router-link to="/open-bank-account/upload-passport-size-photo" class="btn btn-outline-danger btn-mw mr-sm-1 mr-0 my-sm-0 my-2 btn-lg">Back</router-link>
                                                            <b-button class="btn-mw" type="submit" variant="primary" size="lg">Next</b-button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card card-top-line" v-if="accountDetails.displayUploadDocumentSection">
                                            <div class="card-body">
                                                <div class="page-card-group your-id-page">
                                                    <div class="img-or-selfie">
                                                        <!-- Image Upload-->
                                                        <div class="img-upload-area">
                                                            <ValidationProvider name="profile_picture" :rules="!accountDetails.takeSelfie ? 'required' : ''" v-slot="{ errors}">
                                                                <div class="form-group plus-sign upload-Photograph-box">
                                                                    <div class="custom-file">
                                                                        <label class="custom-file-label d-flex flex-column py-5" for="uploadDocumentInput">
                                                                            <input type="hidden" v-model="accountDetails.uploadUserDocuments" accept="image/*"/>
                                                                            <input type="file" id="uploadDocumentInput" ref="uploadDocumentInput" class="form-control custom-file-input" placeholder="" accept="image/*" @change="uploadDocument" />
                                                                            <i class="fa fa-plus fa-2x" v-if="!accountDetails.uploadUserDocuments"></i>
                                                                            <div class="img-uploaded" v-if="accountDetails.uploadUserDocuments">
                                                                                <img class="img-fluid" :src="accountDetails.uploadUserDocuments"/>
                                                                            </div>
                                                                            <p class="mt-3 mb-0" v-if="!accountDetails.uploadUserDocuments"><strong>Click to upload pages of the document</strong><small class="d-block mt-1">File Formate: .png, .jpg only</small><small class="d-block mt-1">File Size between : 102k and 3M</small></p>
                                                                        </label>
                                                                        <span class="text-danger" v-if="accountDetails.uploadUserDocuments"> Click on old image to choose new image</span>
                                                                    </div>
                                                                    <div class="web-camera-container" :class="{'mt-3': accountDetails.uploadUserDocuments}">
                                                                        <div class="camera-shoot" v-if="accountDetails.uploadUserDocuments">
                                                                            <button type="button" class="btn btn-outline-primary btn-mw btn-lg mt-3" @click="removeUploadedDocuments">
                                                                                Remove Documents
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p class="text-danger error-msg-upload" v-if="errors.length && !accountDetails.uploadUserDocuments && !isCameraOpen"> Please upload documents</p>
                                                            </ValidationProvider>
                                                        </div>

                                                        <!-- Or -->
                                                        <div class="or-line" v-if="!accountDetails.uploadUserDocuments">
                                                            <span class="line-text">OR</span>
                                                            <span class="line-draw"></span>
                                                        </div>

                                                        <!-- Selfie Upload-->
                                                        <div class="selfie-area">
                                                            <div class="form-group plus-sign upload-Photograph-box" v-if="!accountDetails.uploadUserDocuments">
                                                                <div class="custom-file"  v-if="accountDetails.takeSelfie">
                                                                    <label class="custom-file-label py-5" for="takeSelfie">
                                                                        <input type="file" id="takeSelfie" ref="takeSelfieInput" class="form-control custom-file-input" placeholder="" accept="image/*"/>
                                                                        <div class="img-uploaded">
                                                                            <img class="img-fluid" :src="accountDetails.takeSelfie"/>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div class="camera-shoot mt-3" v-if="accountDetails.takeSelfie">
                                                                    <button type="button" class="btn btn-outline-primary btn-mw btn-lg" @click="removeSelfieUploadPhoto">
                                                                        Remove Capture Documents
                                                                    </button>
                                                                </div>
                                                                <div class="capture-selfie" v-if="!accountDetails.takeSelfie">
                                                                    <div class="camera-button custom-file-label py-5" :class="{ '' : !isCameraOpen, 'border-danger py-3' : isCameraOpen}" @click="toggleCamera">
                                                                        <p class="mb-0 d-flex flex-column">
                                                                            <i class="fa fa-camera fa-2x"></i>
                                                                            <span v-if="!isCameraOpen"> Take a Selfie </span>
                                                                            <span v-else class="text-danger"> Close Camera </span>
                                                                        </p>
                                                                    </div>
                                                                    <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
                                                                        <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
                                                                        <video :width="150" :height="112.5" v-show="!isPhotoTaken" ref="camera" autoplay></video>
                                                                        <canvas :width="600" :height="450" v-show="isPhotoTaken" id="photoTaken" ref="canvas"></canvas>
                                                                    </div>
                                                                    <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
                                                                        <button type="button" class="btn btn-primary btn-mw btn-lg" @click="takePhoto">
                                                                            <i class="fa fa-camera"></i> Capture A Photograph of Documents
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- Image Guidelines-->
                                                            <div class="image-guidelines" v-if="accountDetails.uploadUserDocuments">
                                                                <div class="border p-3 rounded">
                                                                    <label>Image Guidelines</label>
                                                                    <div class="row">
                                                                        <div class="col-lg-6 col-sl-6">
                                                                            <ul class="list-unstyled">
                                                                                <li class="font-11 line-height-17 mb-0">- Resolution: 8MP recommended</li>
                                                                                <li class="font-11 line-height-17 mb-0">- Not Blurred</li>
                                                                                <li class="font-11 line-height-17 mb-0">- Colour Image</li>
                                                                                <li class="font-11 line-height-17 mb-0">- JPG or PNG file type</li>
                                                                                <li class="font-11 line-height-17 mb-0">- Clear edges</li>
                                                                                <li class="font-11 line-height-17 mb-0">- 90 degree angle(bird's view eye)</li>
                                                                                <li class="font-11 line-height-17 mb-0">- Size between 102k and 3M</li>
                                                                            </ul>
                                                                        </div>
                                                                        <div class="col-lg-6 col-sl-6 d-flex align-items-start justify-content-around">
                                                                            <img src="@/assets/open-account/guidelines-img1.jpg" class="img-fluid">
                                                                            <img src="@/assets/open-account/guidelines-img2.jpg" class="img-fluid">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center justify-content-end flex-column flex-lg-row mt-5">
                                                        <div class="d-flex align-items-center justify-content-between flex-column flex-sm-row">
                                                            <router-link to="/open-bank-account/upload-passport-size-photo" class="btn btn-outline-danger btn-mw mr-sm-1 mr-0 my-sm-0 my-2 btn-lg" @click.native="removesummary()">Back</router-link>
                                                            <b-button class="btn-mw" type="submit" variant="primary" size="lg">Next</b-button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </ValidationObserver>
                            </li>
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,i) in mobileViewTimeLine.inActiveTimeLines" :key="'inActive_'+i">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link"><span>{{ timeline.name }}</span>
                                <i :class="timeline.classList"></i></router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- logo footer -->
                <bank-account-logo-footer/>
                <!-- Expiry Token Modal -->
                <expiry-token-modal></expiry-token-modal>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </section>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default
const BankAccountLogoFooter = require('@/components/admin/open_bank_account_v1/BankAccountLogoFooter.vue').default
const BankAccountFormWizard = require('@/components/admin/open_bank_account_v2/BankAccountFormWizard.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
const ExpiryTokenModal = require('@/components/admin/open_bank_modal/ExpiryTokenModal.vue').default
import moment from 'moment'
var currentDate = new Date();
var currentDatetime = moment(currentDate).format("YYYYMMDDHHmmss");

export default {
    name:'YourIDV2Component',
     components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        'bank-account-logo-footer':BankAccountLogoFooter,
        'bank-account-wizard':BankAccountFormWizard,
        'main-loader':LoaderComponent,
        'expiry-token-modal':ExpiryTokenModal
    },
    data(){
        return{
            accountDetails:{
                country: null,
                displayDocumentRadioSection:false,
                displayUploadDocumentSection:false,
                uploadUserDocuments: null,
                takeSelfie:null,
                errorMsg:'',
                documentName: ''
            },
            base64DocsPhoto: '',
            base64docsSelfie: '',
            timeoutModalShow:false,

            countrys_options: [],

            // countrys_options: [
            //     { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round", isocode:'gb'},
            //     { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round", isocode:'us'},
            //     { name: 'Germany', flagClass:"flag-icon flag-icon-de fi-xl fi-round", isocode:'de'},
            //     { name: 'Italy', flagClass: "flag-icon flag-icon-it fi-xl fi-round", isocode:'it'},
            //     { name: 'Australia', flagClass: "flag-icon flag-icon-au fi-xl fi-round", isocode:'au'},
            //     { name: 'Canada', flagClass: "flag-icon flag-icon-ca fi-xl fi-round", isocode:'ca'},
            //     { name: 'Netherlands', flagClass: "flag-icon flag-icon-nl fi-xl fi-round", isocode:'nl'},
            //     { name: 'Spain', flagClass: "flag-icon flag-icon-es fi-xl fi-round", isocode:'es'},
            //     { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round", isocode:'fr'},
            // ],

            documents:[
                {name:'Driving Licence',value:'driving_licence'},
                {name:'Passport',value:'passport'},
                {name:'National Identification Card',value:'national_identification_card'},
                {name:'Residents Permit',value:'residents_permit'}
            ],

            isCameraOpen: false,
            isPhotoTaken: false,
            isShotPhoto: false,
            isLoading: false,
            uploadPhotograph: false,
            wizardTimeLines:[
                {
                    name:'Account Specification',
                    links:'/open-bank-account/account-specification',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Personal Information',
                    links:'/open-bank-account/personal-information',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Next of Kin',
                    links:'/open-bank-account/your-next-of-kin',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Required Documents',
                    links:'/open-bank-account/upload-passport-size-photo',
                    filledClass:false,
                    activeClass:true
                },
                {
                    name:'Confirm and Sign',
                    links:'/open-bank-account/declarations-and-signatures',
                    filledClass:false,
                    activeClass:false
                }
            ],

            mobileViewTimeLine:{
                activeTimeLines:[
                    {
                        name: 'Account Specification',
                        link: '/open-bank-account/account-specification',
                        classList: 'fa fa-chevron-right',
                        filledClass:true,
                        activeClass:false
                    },
                    {
                        name: 'Personal Information',
                        link: '/open-bank-account/personal-information',
                        classList: 'fa fa-chevron-right',
                        filledClass:true,
                        activeClass:false
                    },
                    {
                        name: 'Next of Kin',
                        link: '/open-bank-account/your-next-of-kin',
                        classList: 'fa fa-chevron-right',
                        filledClass:true,
                        activeClass:false
                    },
                    {
                        name: 'Required Documents',
                        link:'/open-bank-account/upload-passport-size-photo',
                        classList: 'fa fa-chevron-down',
                        filledClass:false,
                        activeClass:true
                    },
                ],

                inActiveTimeLines:[
                    {
                        name: 'Confirm and Sign',
                        link: '/open-bank-account/declarations-and-signatures',
                        classList: 'fa fa-chevron-right'
                    }
                ]
            }
        }
    },methods:{
        uploadDocumentsSubmit(){
            if(this.accountDetails.displayUploadDocumentSection){
                this.$refs.loader.show();
                let docs = '';
                let doc_type = '';
                if(this.base64DocsPhoto != '' && this.base64docsSelfie != ''){
                    docs = this.base64DocsPhoto;
                    doc_type = 'Uploaded';
                } else if(this.base64DocsPhoto != ''){
                    docs = this.base64DocsPhoto;
                    doc_type = 'Uploaded';
                } else if(this.base64docsSelfie != ''){
                    docs = this.base64docsSelfie;
                    doc_type = 'Scanned';
                }
                
                if(docs == '' && this.accountDetails.uploadUserDocuments != ''){
                    this.$refs.loader.hide();
                    this.$router.push({path:'/open-bank-account/summary'})
                } else {
                    let docsSplit = docs.split('base64,')
                    let governmentDetails = {
                        application_id:sessionStorage.getItem("applicationId"),
                        goverment_id_name:currentDatetime+'.jpg',
                        goverment_id:docsSplit[1],
                        country:this.accountDetails.country.name,
                        id_name:this.accountDetails.documentName,
                        document_type:doc_type
                    }
                    this.$http.post(this.$baseurl+'bankAccount/documents/', governmentDetails, {
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                        },
                    })
                    .then((res) => {
                        //Perform Success Action
                        if(res.data.code == '0057-000') {
                            this.$refs.loader.hide();
                            this.$router.push({path:'/open-bank-account/summary'})
                        } else {
                            this.$refs.loader.hide();
                            this.$swal('Error!', res.message, 'error', 'Close');
                        }
                    })
                    .catch((error) => {
                        // error.response.status Check status code
                        this.$refs.loader.hide();
                        console.log(error);
                    });
                }                
            }else{
                return this.accountDetails.errorMsg = 'Please select checkbox to continue.'
            }
        },

        selectedCountryGet(country){
            this.accountDetails.displayDocumentRadioSection =  true
            this.accountDetails.documentName = '';
            
            if(country.name == 'United Kingdom'){
                this.documents = [{name:'Driving Licence'},{name:'Passport'},{name:'National Identification Card'},{name:'Residents Permit'}]
            }else if(country.name == 'United States'){
                this.documents = [{name:'Driving Licence'},{name:'Passport'}]
            }else{
                this.documents = [
                    // {name:'Driving Licence',value:'driving_licence'},
                    {name:'Passport',value:'passport'},
                    // {name:'National Identification Card',value:'national_identification_card'},
                    // {name:'Residents Permit',value:'residents_permit'}
                    // {name:'Visa',value:'visa'}
                ] 
            }  
            
            //hide and remove uploaded previous selection documents
            this.accountDetails.displayUploadDocumentSection = false
            if(this.accountDetails.uploadUserDocuments){
                this.$refs.uploadDocumentInput.value = null;
                this.accountDetails.uploadUserDocuments = null;
            }
        },

        displayUploadDocument(event){
            this.accountDetails.displayUploadDocumentSection = event.target.checked
            if( this.accountDetails.displayUploadDocumentSection){
                return this.accountDetails.errorMsg = ''
            }
        },

        //manually uplaod image
        uploadDocument(e) {
            if(e.target.files.length > 0){
                let file = e.target.files[0];
                let fileType = file.type;
                if(fileType == 'image/png' || fileType == 'image/jpg' || fileType == 'image/jpeg' || fileType == 'image/JPEG'){
                    // Start For convert and set base64
                    const reader = new FileReader()
                    let rawImg;
                    reader.onloadend = () => {
                        rawImg = reader.result;
                        this.base64DocsPhoto = rawImg;
                    }
                    reader.readAsDataURL(file);
                    // End For convert and set base64
                    this.accountDetails.uploadUserDocuments = URL.createObjectURL(file);
                } else {
                    this.$swal('Error!', 'Please provide image file', 'error', 'Close');
                }
            }
        },

        toggleCamera() {
            if(this.isCameraOpen) {
                this.isCameraOpen = false;
                this.isPhotoTaken = false;
                this.isShotPhoto = false;
                this.uploadPhotograph = false;
                this.stopCameraStream();
            } else {
                this.isCameraOpen = true;
                this.uploadPhotograph = false;
                this.createCameraElement();
            }
        },
    
        createCameraElement() {
            this.isLoading = true;
            const constraints = (window.constraints = {
                audio: false,
                video: true
            });

            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(stream => {
                    this.isLoading = false;
                    this.$refs.camera.srcObject = stream;
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    alert("May the browser didn't support or there is some errors.");
            });
        },
    
        stopCameraStream() {
            let tracks = this.$refs.camera.srcObject.getTracks();
            console.log(tracks);
            tracks.forEach(track => {
                track.stop();
            });
        },

        //using webcam uplaod image
        takePhoto() {
            if(!this.isPhotoTaken) {
                this.isShotPhoto = true;

                const FLASH_TIMEOUT = 50;

                setTimeout(() => {
                    this.isShotPhoto = false;
                }, FLASH_TIMEOUT);
            }
        
            this.isPhotoTaken = !this.isPhotoTaken;
        
            const context = this.$refs.canvas.getContext('2d');
            context.drawImage(this.$refs.camera, 0, 0, 600, 450);

            const canvas = document.getElementById("photoTaken");

            // Start For set base64
            this.base64docsSelfie = canvas.toDataURL();
            // End For set base64

            this.isPhotoTaken = false;
            const selfieFile = this.dataURLtoBlob( canvas.toDataURL() );
            this.accountDetails.takeSelfie = URL.createObjectURL(selfieFile);
            this.stopCameraStream();
            this.isCameraOpen = false;
            this.uploadPhotograph = false;
        },

        toggleUploadPhotograph() {
            this.isCameraOpen = false;
            this.uploadPhotograph = !this.uploadPhotograph;
        },
        
        dataURLtoBlob(dataURL) {
            let array, binary, i, len;
            binary = atob(dataURL.split(',')[1]);
            array = [];
            i = 0;
            len = binary.length;
            while (i < len) {
                array.push(binary.charCodeAt(i));
                i++;
            }
            return new Blob([new Uint8Array(array)], {
                type: 'image/png'
            });
        },

        removeUploadedDocuments(){
            this.$refs.uploadDocumentInput.value = null;
            this.accountDetails.uploadUserDocuments = null;
            this.isCameraOpen = false
            //selfie remove upload value
            setTimeout(() => {
                if(this.accountDetails.takeSelfie){
                    this.$refs.takeSelfieInput.value = null;
                    this.accountDetails.takeSelfie = null;
                }
            }, 100);
        },

        removeSelfieUploadPhoto(){
            this.$refs.takeSelfieInput.value = null;
            this.accountDetails.takeSelfie = null;
            this.isCameraOpen = false 
            //image remove upload value
            setTimeout(() => {
                if(this.accountDetails.uploadUserDocuments){
                    this.$refs.uploadDocumentInput.value = null;
                    this.accountDetails.uploadUserDocuments = null;
                }
            }, 100);
        },

        async getDocumentData(){
            this.$http.get(this.$baseurl+'bankAccount/bank-user-information/', { 
                params: {application_id: sessionStorage.getItem("applicationId")}, 
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                }
            }).then((res) => {
                // console.log(res.data);
                if(res.data.code == '0059-000' && res.data.documents.user_proof_id != ''){
                    //  console.log(res.data.documents);
                    if(res.data.documents.profile_image == ''){
                        this.$swal('Warning!', 'Please provide passport photo first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/upload-passport-size-photo'});
                    } else if(res.data.documents.id_name != null) {
                        this.accountDetails.country = { name: res.data.documents.country};
                        this.accountDetails.displayDocumentRadioSection =  true;
                        this.accountDetails.documentName = res.data.documents.id_name;
                        this.accountDetails.displayUploadDocumentSection = true;
                        this.accountDetails.uploadUserDocuments = 'https://nrba.payangel.com'+res.data.documents.goverment_id;
                    }
                } else {
                    if(res.data.specification_id == '' && res.data.personal_information.user_info_id == '' && res.data.employment.employment_id == '' && res.data.contact_details.contact_id == '' && res.data.documents.user_proof_id == '') {
                        this.$swal('Warning!', 'Please complete account specification first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/account-specification'});
                    } else if(res.data.personal_information.user_info_id == '' && res.data.employment.employment_id == '' && res.data.contact_details.contact_id == '' && res.data.documents.user_proof_id == '') {
                        this.$swal('Warning!', 'Please complete personal information first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/personal-information'});
                    } else if(res.data.employment.employment_id == '' && res.data.contact_details.contact_id == '' && res.data.documents.user_proof_id == '') {
                        this.$swal('Warning!', 'Please complete employment details first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/employment'});
                    } else if(res.data.contact_details.contact_id == '' && res.data.documents.user_proof_id == '') {
                        this.$swal('Warning!', 'Please complete contact details first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/contact-details'});
                    } else if(res.data.documents.user_proof_id == '') {
                        this.$swal('Warning!', 'Please provide passport photo first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/upload-passport-size-photo'});
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
        },

        async getCountryData() {
            try {
                const response = await this.$http.get("https://stand-app.com/api/v1/countries");
                // JSON responses are automatically parsed.
                response.data.map((item) => {
                    let countryData = {
                        name: item.name, 
                        flagClass:"flag-icon flag-icon-"+item.isoCode.toLowerCase()+" fi-xl fi-round",
                        isocode:item.isoCode.toLowerCase()
                    }
                    this.countrys_options.push(countryData);
                })
            } catch (error) {
                console.log(error);
            }
        },

        tokenExpired: function() {
            if(sessionStorage.getItem('tokenExpiryTime')){
                if(!this.timeoutModalShow) {
                    const expiryTime = sessionStorage.getItem('tokenExpiryTime').split('.');
                    const currentDateTime = new Date().toISOString().split('.');
                    if(expiryTime[0] == currentDateTime[0]){
                        this.$bvModal.show('expiry_token');
                        this.timeoutModalShow = true;
                    }
                }   
            }               
        },

        removesummary(){
            if(sessionStorage.getItem("pagename")){
                sessionStorage.removeItem("pagename")
            }
        }
    },beforeDestroy () {
        this.stopCameraStream();
    },created() { 
        setInterval(this.tokenExpired, 1000);
          
        this.getCountryData();     
        if(sessionStorage.getItem("applicationId")){
            this.getDocumentData();
        } else {
            this.$swal('Warning!', 'Please start application here', 'warning', 'Close');
            this.$router.push({path:'/'});
        }
    }
}
</script>
