<template>
    <b-modal id="contact_us" title="Contact" hide-footer modal-class="modal-center" no-stacking no-close-on-backdrop>
        <div class="modal-body">
            <ValidationObserver v-slot="{ passes }">
                <form class="contact-detail" @submit.prevent="passes(contactFormSubmit)" autocomplete="off">
                    <div class="login-card card-mobile">
                        <ValidationProvider ref="contact_regarding" name="contact_regarding" rules="required" v-slot="{ errors}">
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':contactformdata.contact_regarding && !errors[0]}">
                                    <span class="float-label">
                                        <label for="Country">What would you like to contact us about?*</label>
                                        <multiselect
                                            placeholder=""
                                            v-model="contactformdata.contact_regarding" 
                                            :show-labels="false" 
                                            :options="contact_regarding_options" 
                                            :searchable="true" >
                                        </multiselect> 
                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                        <i class="fa fa-check" v-if="contactformdata.contact_regarding && !errors[0]"></i>
                                    </span>
                                </div>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider ref="message" name="message" rules="required" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !contactformdata.message,'success':contactformdata.message}">
                                <span class="float-label">
                                    <textarea type="text" v-model="contactformdata.message" class="form-control" id="message" placeholder="Message*"/>
                                    <label for="message" class="control-label">Type Your Message Here*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !contactformdata.message"></i>
                                    <i class="fa fa-check" v-if="contactformdata.message"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider ref="contactEmail" name="contact_email" rules="required|email" v-slot="{ errors}" vid="confirmation">
                            <div class="form-group" :class="{'errors': errors.length && !contactformdata.contact_email || errors[0],'success':contactformdata.contact_email && !errors[0]}">
                                <span class="float-label">
                                    <input type="email" v-model="contactformdata.contact_email" class="form-control" name="email1" id="contact_email" placeholder="Email*">
                                    <label for="contact_email" class="control-label">Email*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !contactformdata.contact_email || errors[0] "></i>
                                    <i class="fa fa-check" v-if="contactformdata.contact_email && !errors[0]"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div class="form-group" :class="{'errors': mobileNumberError == false,'success':contactformdata.primaryNumber && mobileNumberError}">
                            <div class="float-label">
                                <label for="primary_number" class="control-label">Mobile Number*</label>
                                <vue-tel-input
                                    class="default-country"
                                    v-model="contactformdata.primaryNumber"
                                    v-bind="mobileNumber"
                                    @validate ="mobileInputValidate"
                                    ref="telInput"
                                    v-on:country-changed="countryChanged"
                                >
                                <template v-slot:arrow-icon>
                                    <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                </template>
                                </vue-tel-input>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="mobileNumberError == false"></i>
                                <i class="fa fa-check" v-if="mobileNumberError && contactformdata.primaryNumber"></i>
                            </div>
                        </div>
                        <ValidationProvider ref="full_name" name="full_name" rules="required" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !contactformdata.full_name,'success':contactformdata.full_name}">
                                <span class="float-label">
                                    <input type="text" v-model="contactformdata.full_name" class="form-control" id="full_name" placeholder="Full Name*" @input="validateName($event,'Full Name','full_name')">
                                    <label for="full_name" class="control-label">Full Name*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !contactformdata.full_name"></i>
                                    <i class="fa fa-check" v-if="contactformdata.full_name"></i>
                                </span>
                                <span class="text-danger error-msg" v-if="fieldName == 'Full Name'">{{fieldErrorMsg}}</span>
                            </div>
                        </ValidationProvider>
                            
                        <div class="bottom">
                            <div class="form-group captch-form-group">
                                <!-- localhost => 6Lc87gIfAAAAADWgqpaH7piZW1K1TMaCmCau-tfx -->
                                <invisible-recaptcha sitekey="6Lf_4GYeAAAAALcFTBpXchA2gH3bM85jf5AejDBg" :validate="prepare" :callback="callbackCaptcha" :disabled="btnDesabled" type="submit" name="submit" class="btn btn-green btn-gradiunt btn-block btn-lg">
                                    SUBMIT REQUEST
                                </invisible-recaptcha>
                            </div>
                            <!-- <b-button variant="gradiunt" size="lg" block type="submit" @click="contactFormSubmit()" :disabled="btnDesabled">Submit</b-button>  -->
                        </div>  
                    </div> 
                </form>
            </ValidationObserver>
        </div>
    </b-modal>
</template>
<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
const Multiselect = require('vue-multiselect').default;
import parsePhoneNumber from 'libphonenumber-js'
const { VueTelInput } = require('vue-tel-input')
const InvisibleRecaptcha = require('vue-invisible-recaptcha').default

export default {
    name:'LoginModal',
    components: {
        ValidationObserver,
        ValidationProvider,
        VueTelInput,
        Multiselect,
        "invisible-recaptcha": InvisibleRecaptcha,
    },data(){
        return{
            contactformdata:{
                contact_regarding:null,
                primaryNumber:'',
                contact_email:'',
                full_name:'',
                message:''
            },
            primaryDropdawnOpen:false,
            btnDesabled:false,
            primaryContactISO:'',

            fieldName:'',
            fieldErrorMsg:'',
            loading :false,

            contact_regarding_options:['Registration Support','Login Support','Transaction Support','Other Customer Services','Website Usage Support', 'Complaints','General Enquiry','Feedback'],

            mobileNumberError:'',
            mobileNumber: {
                mode: "auto", 
                defaultCountry: "GB",
                autoFormat:false,
                dropdownOptions:{
                    showFlags: true,
                    // disabled:true,
                    showDialCodeInSelection:true
                } 
            },
        }
    },methods:{
        contactFormSubmit(recaptchaToken){
            if(typeof(this.mobileNumberError) == 'undefined'){
                this.mobileNumberError = false
            }
            
            if(this.mobileNumberError == true){
                // this.btnDesabled = true;
                const primaryNumber = parsePhoneNumber(this.contactformdata.primaryNumber, this.primaryContactISO);

                let contactInformation = {
                    contact_regarding:this.contactformdata.contact_regarding,
                    message:this.contactformdata.message,
                    email:this.contactformdata.contact_email,
                    phone:primaryNumber.number,
                    full_name:this.contactformdata.full_name,
                    recaptcha_token:recaptchaToken
                }
                // console.log(contactInformation);
                this.$http.post(this.$baseurl+'user/contact-us/', contactInformation).then((res) => {
                    //Perform Success Action
                    if(res.data.code == '0072-000') {
                        this.btnDesabled = false;
                        this.$bvModal.hide('contact_us');
                        this.$swal('Success!', 'Your contact us form successfully submitted', 'success', 'Close');
                    } else {
                        this.btnDesabled = false;
                        this.$swal('Error!', res.data.message, 'error', 'Close');
                    }
                })
                .catch((error) => {
                    this.btnDesabled = false;
                    console.log(error);
                });  
            }
        },

        callbackCaptcha(recaptchaToken){
            if(typeof(this.mobileInputError) == 'undefined'){
                this.mobileInputError = true
            }

            if(!this.contactformdata.contact_regarding) {
                this.$refs.contact_regarding.applyResult({
                    errors: ["Please enter the value"],
                    valid: false,
                    failedRules: {}
                });
            }

            if(!this.contactformdata.contact_email) {
                this.$refs.contactEmail.applyResult({
                    errors: ["Please enter the value"],
                    valid: false,
                    failedRules: {}
                });
            }

            if(!this.contactformdata.full_name) {
                this.$refs.full_name.applyResult({
                    errors: ["Please enter the value"],
                    valid: false,
                    failedRules: {}
                });
            }

            if(!this.contactformdata.message) {
                this.$refs.message.applyResult({
                    errors: ["Please enter the value"],
                    valid: false,
                    failedRules: {}
                });
            }

            if(this.mobileInputError){
                console.log(recaptchaToken)
            }
            this.contactFormSubmit(recaptchaToken);
            
        },

        prepare(){
            this.loading = true
            this.btnDesabled = false
        },

        mobileInputValidate(phoneObject){
            this.mobileNumberError = phoneObject.valid
        },

        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        },
        
        setPrimaryNumberCountryCode(defaultCountry){
            this.$refs.telInput.choose(defaultCountry.primaryNumberCode)
            this.mobileNumber.defaultCountry = defaultCountry.primaryNumberCode
        },

        countryChanged(country) {
            this.primaryContactISO = country.iso2;
        },

        validateName(e,fieldType,fieldName) {
            let char = e.target.value; //String.fromCharCode(e.keyCode); // Get the character
            let charLength = e.target.value.length;

            var splitStr = e.target.value.split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
            }
            this.contactformdata[fieldName] = splitStr.join(' ');
            
            if(/^[A-Za-z'-\s]+$/.test(char)) {
                if(charLength >= 2 && charLength <= 35 && splitStr[0] != '') {
                    this.fieldErrorMsg = '';
                    this.fieldName = '';
                    return true; // Match with regex 
                } else {
                    this.fieldName = fieldType;
                    this.fieldErrorMsg = 'Please provide minimum two characters.';
                }
            } else {
                this.fieldName = fieldType;
                this.fieldErrorMsg = 'Please provide valid '+fieldType;
                e.preventDefault(); // If not match, don't add to input text
            }
        },
    }
}
</script>
<style>
.btn-gradiunt {
    background: linear-gradient(103.59deg, #009396 0%, #6cb945 100%);
    transition: 0.5s;
    color: #fff;
}
</style>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>