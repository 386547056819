<template>
     <section class="main-content">
        <div class="cms-content">
            <div class="container">
                <div class="row justify-content-center">
                    <!-- smartwizard -->
                    <bank-account-wizard :wizardTimeLines="wizardTimeLines"/>

                    <div class="col-lg-8 col-md-10 col-sm-12">
                        <ul class="wizard-mobile list-unstyled">
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,i) in mobileViewTimeLine.activeTimeLines" :key="'active_'+i">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link" :class="{'filled':timeline.filledClass,'active':timeline.activeClass}">
                                    <span>{{ timeline.name }}</span>
                                    <i :class="timeline.classList"></i>
                                </router-link>
                            </li>
                            <li class="main-form-area">
                                <ValidationObserver v-slot="{ passes }">
                                    <form class="employment" @submit.prevent="passes(employment)" autocomplete="off">
                                        <div class="card card-top-line">
                                            <div class="card-body">
                                                <h2 class="page-title">Employment</h2>
                                                <div class="row">
                                                    <div class="col-md-6 col-12">
                                                        <ValidationProvider name="occupation" rules="required" v-slot="{ errors}">
                                                            <div class="form-group" :class="{'errors': errors.length && !accountDetails.occupation,'success':accountDetails.occupation, 'mb-0':apiErrorCode == 'V0054-004'}">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="accountDetails.occupation" class="form-control" id="occupation" placeholder="Occupation*" @input="capitalizeFirstLetter($event,'occupation')">
                                                                    <label for="occupation" class="control-label">Occupation*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.occupation"></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.occupation"></i>
                                                                </span>
                                                                <span class="text-danger error-msg" v-if="fieldName == 'occupation'">{{fieldErrorMsg}}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0054-004'">{{apiErrorMessage}}</span>
                                                        <div class="phone-with-country d-flex justify-content-between align-items-center">
                                                            <div class="select-country">
                                                                <div class="form-group form-select single-multiselect">
                                                                    <span class="float-label">
                                                                        <multiselect
                                                                            placeholder=""
                                                                            v-model="accountDetails.currency"   
                                                                            :show-labels="false" 
                                                                            :options="currency_options"
                                                                            :searchable="false"
                                                                            label="name" 
                                                                            track-by="name"
                                                                            :custom-label="currencyFlag">
                                                                            <template slot="singleLabel" slot-scope="props">
                                                                                <span class="mr-2" :class="props.option.flagClass"></span>
                                                                                <span class="option-title">
                                                                                    <span>{{ props.option.name }}</span>
                                                                                </span>
                                                                            </template>
                                                                            <template slot="option" slot-scope="props">
                                                                                <div class="option-title">
                                                                                    <span class="mr-2" :class="props.option.flagClass"></span>
                                                                                    <span>{{ props.option.name }}</span>
                                                                                </div>
                                                                            </template>
                                                                        </multiselect> 
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="add-number">
                                                                <ValidationProvider name="grossIncome" rules="required" v-slot="{ errors}">
                                                                    <div class="form-group" :class="{'errors': errors.length && !accountDetails.grossIncome,'success':accountDetails.grossIncome}">
                                                                        <span class="float-label">
                                                                            <!-- <input type="text" v-model="accountDetails.grossIncome" class="form-control" id="grossIncome" placeholder="0.00"> -->
                                                                            <input type="text" v-model="accountDetails.grossIncome" class="form-control" id="grossIncome" placeholder="0.00" @keydown="checkDecimals($event)"  @keyup="handleInput($event)" @change="makeDecimal($event)">
                                                                            <label for="grossIncome" class="control-label">Gross Income*</label>
                                                                            <div class="line"></div>
                                                                            <i class="fa fa-times" v-if="errors.length && !accountDetails.grossIncome"></i>
                                                                            <i class="fa fa-check" v-if="accountDetails.grossIncome"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                        <span class="text-danger d-block" v-if="apiErrorCode == 'V0054-006'">{{apiErrorMessage}}</span>
                                                    </div>
                                                    <div class="col-md-6 col-12">
                                                        <ValidationProvider name="nameofEmployer" rules="required" v-slot="{ errors}">
                                                            <div class="form-group" :class="{'errors': errors.length && !accountDetails.nameofEmployer,'success':accountDetails.nameofEmployer, 'mb-0':apiErrorCode == 'V0054-005'}">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="accountDetails.nameofEmployer" class="form-control" id="nameofEmployer" placeholder="Name of Employer*" @input="capitalizeFirstLetter($event,'nameofEmployer')">
                                                                    <label for="nameofEmployer" class="control-label">Name of Employer*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.nameofEmployer"></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.nameofEmployer"></i>
                                                                </span>
                                                                <span class="text-danger error-msg" v-if="fieldName == 'nameofEmployer'">{{fieldErrorMsg}}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0054-005'">{{apiErrorMessage}}</span>
                                                        <ValidationProvider name="income_period" rules="required" v-slot="{ errors}">
                                                            <div class="country-dropdown">
                                                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':accountDetails.income_period && !errors[0], 'mb-0':apiErrorCode == 'V0054-007'}">
                                                                    <span class="float-label">
                                                                    <label for="income_period">Income Period*</label>
                                                                        <multiselect
                                                                            placeholder=""
                                                                            v-model="accountDetails.income_period" 
                                                                            :show-labels="false" 
                                                                            :options="incomePeriod_options" 
                                                                            :searchable="false" >
                                                                        </multiselect> 
                                                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                        <i class="fa fa-check" v-if="accountDetails.income_period && !errors[0]"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0054-007'">{{apiErrorMessage}}</span>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-end flex-column flex-lg-row">
                                                    <div class="d-flex align-items-center justify-content-between flex-column flex-sm-row">
                                                        <router-link to="/open-bank-account/personal-information" class="btn btn-outline-danger btn-mw mr-sm-1 mr-0 my-sm-0 my-2 btn-lg" @click.native="removesummary()">Back</router-link>
                                                        <b-button class="btn-mw" type="submit" variant="primary" size="lg">Next</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </ValidationObserver>
                            </li>
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,i) in mobileViewTimeLine.inActiveTimeLines" :key="'inActive_'+i">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link"><span>{{ timeline.name }}</span>
                                <i :class="timeline.classList"></i></router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- logo footer -->
                <bank-account-logo-footer/>
                <!-- Expiry Token Modal -->
                <expiry-token-modal></expiry-token-modal>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </section>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default
const BankAccountLogoFooter = require('@/components/admin/open_bank_account_v1/BankAccountLogoFooter.vue').default
const BankAccountFormWizard = require('@/components/admin/open_bank_account_v2/BankAccountFormWizard.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
const ExpiryTokenModal = require('@/components/admin/open_bank_modal/ExpiryTokenModal.vue').default
export default {
    name:'EmploymentV2Component',
    components:{
        Multiselect,
        ValidationObserver,
        ValidationProvider,
        'bank-account-logo-footer':BankAccountLogoFooter,
        'bank-account-wizard':BankAccountFormWizard,
        'main-loader':LoaderComponent,
        'expiry-token-modal':ExpiryTokenModal
    },
    data(){
        return{
             accountDetails:{
                occupation:'',
                nameofEmployer:'',
                grossIncome:'',
                currency:{ name: 'GBP', flagClass: "flag-icon flag-icon-gb fi-md fi-round", isocode:'gb'},
                income_period:null,
                iso_code:''
            },
            timeoutModalShow:false,
            apiErrorCode:'',
            apiErrorMessage:'',
            currency_options:[
                { name: 'GBP', flagClass: "flag-icon flag-icon-gb fi-md fi-round", isocode:'gb'},
                { name: 'GHS', flagClass: "flag-icon flag-icon-gh fi-md fi-round", isocode:'gh'},
                { name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round", isocode:'us'},
                { name: 'EUR', flagClass:"flag-icon flag-icon-eu fi-md fi-round", isocode:'eu'},
            ],
            incomePeriod_options:['Yearly','Monthly'],

            wizardTimeLines:[
                {
                    name:'Account Specification',
                    links:'/open-bank-account/account-specification',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Personal Information',
                    links:'/open-bank-account/personal-information',
                    filledClass:false,
                    activeClass:true
                },
                {
                    name:'Next of Kin',
                    links:'/open-bank-account/your-next-of-kin',
                    filledClass:false,
                    activeClass:false
                },
                {
                    name:'Required Documents',
                    links:'/open-bank-account/upload-passport-size-photo',
                    filledClass:false,
                    activeClass:false
                },
                {
                    name:'Confirm and Sign',
                    links:'/open-bank-account/declarations-and-signatures',
                    filledClass:false,
                    activeClass:false
                }
            ],

            mobileViewTimeLine:{
                activeTimeLines:[
                    {
                        name: 'Account Specification',
                        link: '/open-bank-account/account-specification',
                        classList: 'fa fa-chevron-right',
                        filledClass:true,
                        activeClass:false
                    },
                    {
                        name: 'Personal Information',
                        link: '/open-bank-account/personal-information',
                        classList: 'fa fa-chevron-down',
                        filledClass:false,
                        activeClass:true
                    },
                ],

                inActiveTimeLines:[
                    {
                        name: 'Next of Kin',
                        link: '/open-bank-account/your-next-of-kin',
                        classList: 'fa fa-chevron-right'
                    },
                    {
                        name: 'Required Documents',
                        link:'/open-bank-account/upload-passport-size-photo',
                        classList: 'fa fa-chevron-right'
                    },
                    {
                        name: 'Confirm and Sign',
                        link: '/open-bank-account/declarations-and-signatures',
                        classList: 'fa fa-chevron-right'
                    }
                ]
            }
        }
    },methods:{
        employment(){
            this.$refs.loader.show();
            let employmentDetails = {
                application_id:sessionStorage.getItem("applicationId"),
                occupation:this.accountDetails.occupation,
                name_of_employer:this.accountDetails.nameofEmployer,
                gross_income:this.accountDetails.grossIncome,
                iso_code:this.accountDetails.currency.isocode,
                currency_type:this.accountDetails.currency.name,
                income_period:this.accountDetails.income_period
            }
            // console.log(employmentDetails);
            this.$http.post(this.$baseurl+'bankAccount/employment/', employmentDetails, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                },
            })
            .then((res) => {
                //Perform Success Action
                if(res.data.code == '0054-000') {
                    this.$refs.loader.hide();
                    if(sessionStorage.getItem("pagename") && sessionStorage.getItem("pagename") == 'Summary'){
                        sessionStorage.removeItem("pagename")
                        this.$router.push({path:'/open-bank-account/summary'});
                    } else {
                        this.$router.push({path:'/open-bank-account/contact-details'})
                    }
                } else {
                    this.$refs.loader.hide();
                    this.$swal('Error!', res.message, 'error', 'Close');
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$refs.loader.hide();
                this.apiErrorCode = error.response.data.code;
                this.apiErrorMessage = error.response.data.message;
            });  
            
        },

        currencyFlag ({ name, flagClass }) {
            return `${flagClass} - ${name}`
        },

        checkDecimals (e) {
            let stringValue = e.target.value.toString();
            let regex = /^[0-9]*(\.[0-9]{0,2})?$/
            if(!stringValue.match(regex) && e.key != "Backspace" && e.key != "Tab" && e.key != "Control" && e.key != "Shift") {
                e.preventDefault();
            }
        },
        handleInput (e) {
            let stringValue = e.target.value.toString();
            let regex = /^[0-9]*(\.[0-9]{0,2})?$/
            if(!stringValue.match(regex) && e.key != "Backspace" && e.key != "Tab" && e.key != "Control" && e.key != "Shift") {
                this.accountDetails.grossIncome = e.target.value = stringValue.slice(0, -1);
                e.preventDefault();
            }
        },
        makeDecimal (e) {
            let floatValue = parseFloat(e.target.value);
            // this.accountDetails.grossIncome = e.target.value = floatValue.toFixed(2);
            var that = this;
            if(floatValue.toFixed(2) == 'Nan' || floatValue.toFixed(2) == 'NaN') {
                that.apiErrorCode = 'V0054-006';
                that.apiErrorMessage = 'Please provide valid number';
            } else {
                that.apiErrorCode = '';
            }
            
            this.accountDetails.grossIncome = e.target.value = floatValue.toFixed(2) != 'Nan' ? floatValue.toFixed(2) : '';
        },

        async getEmploymentData() {
            this.$http.get(this.$baseurl+'bankAccount/bank-user-information/', { 
                params: {application_id: sessionStorage.getItem("applicationId")}, 
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                }
            }).then((res) => {
                //Perform Success Action
                // console.log(res.data);
                if(res.data.code == '0059-000' && res.data.employment.employment_id != ''){
                    let employmentData = res.data.employment;
                    this.accountDetails.occupation = employmentData.occupation;
                    this.accountDetails.nameofEmployer = employmentData.name_of_employer;
                    this.accountDetails.grossIncome = employmentData.gross_income;
                    this.accountDetails.currency = { name: employmentData.currency_type, isocode: employmentData.iso_code, flagClass: "flag-icon flag-icon-"+employmentData.iso_code+" fi-md fi-round" };
                    this.accountDetails.income_period = employmentData.income_period;
                } else {
                    if(res.data.specification_id == '' && res.data.personal_information.user_info_id == '') {
                        this.$swal('Warning!', 'Please complete account specification first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/account-specification'});
                    } else if(res.data.personal_information.user_info_id == '') {
                        this.$swal('Warning!', 'Please complete Personal Information first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/personal-information'});
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                // this.apiErrorCode = error.response.data.code;
            })
            
        },

        capitalizeFirstLetter(event,fieldName){
            var splitStr = event.target.value.split(' ');
            if(splitStr[0] == ''){
                this.fieldName = fieldName;
                this.fieldErrorMsg = 'Please provide minimum two characters.';
            } else {
                this.fieldErrorMsg = '';
                this.fieldName = '';
                for (var i = 0; i < splitStr.length; i++) {
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                }
                // Directly return the joined string
                this.accountDetails[fieldName] = splitStr.join(' ');
            }
        },

        tokenExpired: function() {
            if(sessionStorage.getItem('tokenExpiryTime')){
                if(!this.timeoutModalShow) {
                    const expiryTime = sessionStorage.getItem('tokenExpiryTime').split('.');
                    const currentDateTime = new Date().toISOString().split('.');
                    if(expiryTime[0] == currentDateTime[0]){
                        this.$bvModal.show('expiry_token');
                        this.timeoutModalShow = true;
                    }
                }   
            }              
        },

        removesummary(){
            if(sessionStorage.getItem("pagename")){
                sessionStorage.removeItem("pagename")
            }
        }
    },created() {
        setInterval(this.tokenExpired, 1000);
        // console.log(sessionStorage.getItem("jwtAccessToken"));
        // console.log(sessionStorage.getItem("applicationId"));
        if(sessionStorage.getItem("applicationId")){
            this.getEmploymentData();
        } else {
            this.$swal('Warning!', 'Please start application here', 'warning', 'Close');
            this.$router.push({path:'/'});
        }
    }
}
</script>

