<template>
    <section class="main-content">
        <div class="cms-content">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-8 col-lg-9 col-md-10 col-sm-12">
                        <div class="card card-top-line">
                            <div class="card-body">
                                <h2 class="page-title">About This Service</h2>
                                <p>Thanks for choosing our Non-Resident Account Opening Service</p>
                                <p>This service allows you to open a bank account in Ghana. The process is seamless and takes less than 5 minutes to complete. Your account will be opened with one of the participating banks below: </p>
                                <ul class="list-unstyled d-flex flex-md-row flex-column my-5">
                                    <li class="mr-0 mr-md-5 mb-5 mb-md-0"><img src="@/assets/bank/bank_cbg.png" alt="cbg bank" height="40"></li>
                                     <li class="mr-0 mr-md-5 mb-5 mb-md-0"><img src="@/assets/bank/bank_fidelity.png" alt="fidelity bank" height="59"></li>
                                    <li><img src="@/assets/bank/bank_access.png" alt="access bank" height="59"></li> 
                                </ul>
                                <div class="step-1-footer d-flex align-items-center justify-content-end flex-column flex-lg-row mt-4">
                                    <b-button class="btn-mw mt-sm-0 mt-3" variant="primary" size="lg" @click="openNewAccount()">Start Application</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Personal Info modal -->
                <personal-info-modal></personal-info-modal>
                <!-- Otp Modal -->
                <otp-modal></otp-modal>
                <!-- Login Modal -->
                <login-modal></login-modal>
                <!-- Application Modal -->
                <application-modal></application-modal>
                <!-- First Applicant Modal for Joint Account -->
                <first-applicant-modal></first-applicant-modal>
                <!-- Second Applicant Modal for Joint Account -->
                <second-applicant-modal></second-applicant-modal>
                <!-- Application Otp Modal for Joint Account -->
                <joint-application-otp></joint-application-otp>
                <!-- logo footer -->
                <bank-account-logo-footer/>
            </div>
        </div>
    </section>
</template>
<script>
const PersonalInfoModal  = require('@/components/admin/open_bank_modal/PersonalInfoModal.vue').default
const OtpModal = require('@/components/admin/open_bank_modal/OtpModal.vue').default
const LoginModal = require('@/components/admin/open_bank_modal/LoginModal.vue').default
const ApplicationIdModal = require('@/components/admin/open_bank_modal/ApplicationIdModal.vue').default
const FirstApplicantModal = require('@/components/admin/open_bank_modal/FirstApplicantModal.vue').default
const SecondApplicantModal = require('@/components/admin/open_bank_modal/SecondApplicantModal.vue').default
const JointApplicationOtp = require('@/components/admin/open_bank_modal/JointApplicationOtp.vue').default
const BankAccountLogoFooter = require('@/components/admin/open_bank_account_v1/BankAccountLogoFooter.vue').default
import '@/plugins/sweetalert2'
export default {
    name:'OpenAccountVariant2Component',
    components:{
        'personal-info-modal':PersonalInfoModal,
        'otp-modal':OtpModal,
        'login-modal':LoginModal,
        'application-modal':ApplicationIdModal,
        'first-applicant-modal':FirstApplicantModal,
        'second-applicant-modal':SecondApplicantModal,
        'joint-application-otp':JointApplicationOtp,
        'bank-account-logo-footer':BankAccountLogoFooter
    },
    methods:{
        openNewAccount(){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-danger btn-lg',
                },
                buttonsStyling: false
            })

            //modal 1st
            swalWithBootstrapButtons.fire({
                icon: 'info',
                width:550,
                html: '<h4>This process will take about 5 mins to complete.</h4>',
                confirmButtonText: 'Got it!',
                // cancelButtonText: 'NO',
                // showCancelButton: true,
                allowOutsideClick: false,
                showCloseButton: true
            }).then((result) => {
                if(result.dismiss == 'close'){
                    this.$swal.close();
                } else {
                    if (result.isConfirmed) {
                        //modal 2nd
                        this.payangelAccountModal();
                    }
                }
            })
        },

        //modal 2nd
        payangelAccountModal(){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-danger btn-lg',
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                icon: 'question',
                width:550,
                title: 'Do you have a PayAngel Account?',
                showCancelButton: true,
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                allowOutsideClick: false,
                showCloseButton: true
            }).then((result) => {
                if(result.dismiss == 'close'){
                    this.$swal.close();
                } else {
                    if (result.isConfirmed) {
                        this.$bvModal.show('login_register');
                        // this.$router.push({ path: '/authentication/page-login' })
                    } else {
                        //modal 3rd
                        this.governmentIdModal();
                    }
                }
                
            })
        },

        //modal 3rd
        governmentIdModal(){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-danger btn-lg',
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                icon: 'question',
                width:550,
                title: 'Do you have your government issued ID ready with you?',
                showCancelButton: true,
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                allowOutsideClick: false,
                showCloseButton: true
            }).then((result) => {
                if(result.dismiss == 'close'){
                    this.$swal.close();
                } else {
                    if (result.isConfirmed) {
                        //modal 5th
                        this.selfieModal();
                    } else {
                        // modal 4th
                        this.governmentIdInfo();
                    }
                }
            })
        },

        //modal 4th
        governmentIdInfo(){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-danger btn-lg',
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                icon: 'info',
                width:550,
                title: 'You will need an ID to complete the application',
                confirmButtonText: 'Continue',
                // cancelButtonText: 'NO',
                // showCancelButton: true,
                buttonsStyling: false,
                allowOutsideClick: false,
                showCloseButton: true
            }).then((result) => {
                if(result.dismiss == 'close'){
                    this.$swal.close();
                } else {
                    if (result.isConfirmed) {
                        //modal 5th
                        this.selfieModal();
                    }
                }
            })
        },

        //modal 5th
        selfieModal(){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-danger btn-lg',
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                icon: 'question',
                width:550,
                title: 'Can you take a selfie or upload a passport size photo with your device?',
                showCancelButton: true,
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                buttonsStyling: false,
                allowOutsideClick: false,
                showCloseButton: true
            }).then((result) => {
                if(result.dismiss == 'close'){
                    this.$swal.close();
                } else {
                    if (result.isConfirmed) {
                        //modal 8th
                        this.jointAccountModal();
                    } else {
                        // modal 6th
                        this.passportModal();
                    }
                }
            })
        },

        // modal 6th
        passportModal(){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-danger btn-lg',
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                icon: 'question',
                width:550,
                title: 'Do you have a recent passport picture to upload?',
                showCancelButton: true,
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                buttonsStyling: false,
                allowOutsideClick: false,
                showCloseButton: true
            }).then((result) => {
                if(result.dismiss == 'close'){
                    this.$swal.close();
                } else {
                    if (result.isConfirmed) {
                        //modal 8th
                        this.jointAccountModal();
                    } else {
                        // modal 7th
                        this.passportInfo();
                    }
                }
            })
        },

        //modal 7th
        passportInfo(){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-danger btn-lg',
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                icon: 'info',
                width:550,
                title: 'You will need a profile photo or a Selfie image to complete the application',
                // showCancelButton: true,
                confirmButtonText: 'Continue',
                // cancelButtonText: 'NO',
                buttonsStyling: false,
                allowOutsideClick: false,
                showCloseButton: true
            }).then((result) => {
                if(result.dismiss == 'close'){
                    this.$swal.close();
                } else {
                    if (result.isConfirmed) {
                        //modal 8th
                        this.jointAccountModal();
                    }
                }
            })
        },

        //modal 8th
        jointAccountModal(){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-danger btn-lg',
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                icon: 'question',
                width:550,
                title: 'Is this a Joint Account Application?',
                showCancelButton: true,
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                buttonsStyling: false,
                allowOutsideClick: false,
                showCloseButton: true
            }).then((result) => {
                if(result.dismiss == 'close'){
                    this.$swal.close();
                } else {
                    if (!result.isConfirmed) {
                        this.$bvModal.show('personal_info');
                        // this.$router.push({ path: '/open-bank-account/account-specification' })
                    } else {
                        this.$bvModal.show('first_applicant_info');
                        // this.haveapplicationId();
                    }
                }
            })
        },

        // modal 9th
        // haveapplicationId(){
        //     const swalWithBootstrapButtons = this.$swal.mixin({
        //         customClass: {
        //             confirmButton: 'btn btn-primary btn-lg my-3',
        //             cancelButton: 'btn btn-outline-danger btn-lg',
        //         },
        //         buttonsStyling: false
        //     })
        //     swalWithBootstrapButtons.fire({
        //         icon: 'question',
        //         width:550,
        //         title: 'Do you have Joint Account Application ID?',
        //         showCancelButton: true,
        //         confirmButtonText: 'YES',
        //         cancelButtonText: 'NO',
        //         allowOutsideClick: false
        //         showCloseButton: true
        //     }).then((result) => {
        //         if (!result.isConfirmed) {
        //             this.$bvModal.show('personal_info');
        //         } else{
        //             this.$bvModal.show('application_id');
        //         }
        //     })
        // }
    },created() {
        // this.$root.$refs.openNewAccount = this;
    }
}
</script>